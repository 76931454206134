<template>
  <h3>Overzicht</h3>
  <div class="row">
    <div class="col-md-6 card m-3">
      <h4>Aantal elementen</h4>
      <h2 v-if="info.element_count">{{ info.element_count }}</h2>
    </div>

    <div class="col-md-6 card m-3">
      <h4>Uitgevoerd onderhoud</h4>
      <h2 v-if="info.executed_maintenance">{{ info.executed_maintenance }}</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 card m-3">
      <h4>Voorbeeld Grafiek</h4>
      <Chart type="bar" :data="barChartData" :options="barChartOptions" />
    </div>

    <div class="col-md-6 card m-3">
      <h4>Voorbeeld grafiek</h4>
      <Chart type="line" :data="lineChartData" :options="lineChartOptions" />
    </div>
  </div>
</template>

<script>
import Menu from "primevue/menu";
import Chart from "primevue/chart";
import { useRouter } from "vue-router";
import Axios from "axios";

export default {
  name: "AdminComponent",
  components: {
    Menu,
    Chart,
  },
  data() {
    return {
      info: {},
      // Data voor de Bar Chart
      barChartData: {
        labels: ["Januari", "Februari", "Maart", "April", "Mei"],
        datasets: [
          {
            label: "Omzet",
            backgroundColor: "#42A5F5",
            data: [65, 59, 80, 81, 56],
          },
          {
            label: "Kosten",
            backgroundColor: "#FFA726",
            data: [28, 48, 40, 19, 86],
          },
        ],
      },
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },

      // Data voor de Line Chart
      lineChartData: {
        labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
        datasets: [
          {
            label: "Bezoekers",
            fill: false,
            borderColor: "#42A5F5",
            data: [120, 150, 180, 220],
          },
        ],
      },
      lineChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      const response = await Axios.get("/beheer/dashboard", {});

      console.log(response);
      this.info = response.data;
    },
  },
};
</script>

<style scoped>
.card {
  padding: 20px;
  height: 350px;
}
h4 {
  margin-bottom: 20px;
}
</style>
