<template>
  <div class="col-md-2">
    <div class="flex justify-center">
      <Menu :model="items"></Menu>
    </div>
  </div>

  <div class="col-md-10">
    <router-view :organisation="organisation"></router-view>
  </div>
</template>

<script>
import Menu from "primevue/menu";

export default {
  name: "AdminComponent",
  components: {
    Menu,
  },
  props: {
    organisation: {},
    user: {},
  },
  data() {
    return {
      items: [
        {
          label: "Overzicht",
          command: () => {
            this.navigateTo("/beheer");
          },
        },
        {
          label: "Gebruikers",
          items: [
            {
              label: "Overzicht",
              icon: "pi pi-align-justify",
              command: () => {
                this.navigateTo("/beheer/users");
              },
            },
            {
              label: "Bewerken/toevoegen",
              icon: "pi pi-plus",
              command: () => {
                this.navigateTo("/beheer/users/new");
              },
            },
          ],
        },
        {
          label: "Rollen",
          items: [
            {
              label: "Overzicht",
              icon: "pi pi-align-justify",
              command: () => {
                this.navigateTo("/beheer/roles");
              },
            },
            {
              label: "Bewerken/toevoegen",
              icon: "pi pi-plus",
              command: () => {
                this.navigateTo("/beheer/roles/new");
              },
            },
          ],
        },
        // {
        //     label: 'Instellingen',
        //     items: [
        //         {
        //             label: 'Settings',
        //             icon: 'pi pi-cog'
        //         },
        //         {
        //             label: 'Logout',
        //             icon: 'pi pi-sign-out'
        //         }
        //     ]
        // }
      ],
    };
  },
  mounted() {},
  methods: {
    navigateTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style>
</style>
