<template>
  <div>
    <div class="createTools pt-1 pb-1">
      <div class="card-text btn-group">
        <button
          v-on:click="startDraw('LineString')"
          :class="[
            'btn btn-default',
            { active: this.$store.getters.activeTool === 'CreateLineString' },
          ]"
          type="button"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Nieuw lijnelement tekenen"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="currentColor"
            class="bi bi-pen"
            viewBox="0 0 16 16"
          >
            <path
              d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a.5.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"
            />
          </svg>
        </button>
        <button
          v-on:click="startDraw('Polygon')"
          :class="[
            'btn btn-default',
            { active: this.$store.getters.activeTool === 'CreatePolygon' },
          ]"
          type="button"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Nieuw vlakelement tekenen"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="currentColor"
            class="bi bi-pentagon"
            viewBox="0 0 16 16"
          >
            <path
              d="m8 1.288 6.842 5.56L12.267 15H3.733L1.158 6.847 8 1.288zM16 6.5 8 0 0 6.5 3 16h10l3-9.5z"
            />
          </svg>
        </button>
        <button
          v-on:click="startDraw('Point')"
          :class="['btn btn-default', { active: selected === 'Point' }]"
          type="button"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Nieuw puntelement tekenen"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="currentColor"
            class="bi bi-circle-fill"
            viewBox="0 0 16 16"
          >
            <circle cx="8" cy="8" r="8" />
          </svg>
        </button>
      </div>
    </div>
    <div
      v-if="$store.getters.newFeature && $store.getters.activeTab === 'Create'"
    >
      <dynamic-form
        v-if="formStructure.length > 0"
        :form-structure="formStructure"
        :organisation-id="organisation.id"
        :element="element"
        :elementsData="elementsData"
        @submit="processRequest"
        @cancel="cancel"
      ></dynamic-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import Draw from "ol/interaction/Draw";
import { Snap, Modify } from "ol/interaction";
import { shiftKeyOnly } from "ol/events/condition";
import { Circle, Fill, Stroke, Style } from "ol/style";
import DynamicForm from "./DynamicForm.vue";

export default {
  name: "CreateComponent",
  components: {
    DynamicForm,
  },
  props: {
    organisation: Object,
    user: Object,
    elementsData: Object,
  },
  data() {
    return {
      selected: "",
      draw: {
        modifyDraw: undefined,
        snap: undefined,
      },
      drawLayer: null,
      element: {
        cover: 0,
        diameter_id: 18,
        element_type: undefined,
        objective_id: 68,
        shape_id: 45,
        number_in_row: undefined,
        owners: [],
      },
      formStructure: [],
    };
  },
  watch: {
    elementsData: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.setupFormStructure(newVal);
        }
      },
    },
  },
  methods: {
    setupFormStructure(elementsData) {
      this.formStructure = [
        {
          type: "select",
          model: "element_type",
          required: true,
          name: "element_type",
          label: "Elementtype",
          key: "elementtype_nl",
          options: elementsData.element_typesArr,
          organisations: "*",
        },
        {
          type: "select",
          model: "shape_id",
          required: false,
          name: "shape_id",
          label: "Vorm",
          options: elementsData.shapesArr,
          organisations: "*",
        },
        {
          type: "select",
          model: "location_id",
          required: false,
          name: "location_id",
          label: "Locatie",
          options: elementsData.locationsArr,
          organisations: "*",
        },
        {
          type: "select",
          model: "objective_id",
          required: false,
          name: "objective_id",
          label: "Streefbeeld",
          options: elementsData.objectivesArr,
          organisations: "*",
        },
        {
          type: "select",
          model: "diameter_id",
          required: false,
          name: "diameter_id",
          label: "Diameter",
          options: elementsData.diametersArr,
          organisations: "*",
        },
        {
          type: "select",
          model: "cover",
          required: false,
          name: "cover",
          label: "Bedekkingsgraad",
          organisations: "*",
        },
        {
          type: "number",
          model: "number_in_row",
          required: false,
          name: "number_in_row",
          label: "Aantal",
          organisations: "*",
        },
        {
          type: "multi-select",
          model: "owners",
          required: false,
          name: "owners",
          label: "Eigenaren",
          options: elementsData.ownersArr,
          organisations: "*",
        },
      ];
    },
    startDraw(type) {
      this.$store.commit("setActiveTab", "Create");
      if ("Create" + type === this.$store.getters.activeTool) {
        if (!this.$store.getters.infoClickState)
          this.$store.dispatch("toggleClickOn");
        this.$store.getters.getMap.removeInteraction(
          this.$store.getters.interaction
        );

        this.$store.commit("newInteraction", undefined);
        this.$store.commit("activeTool", undefined);
        this.$store.commit("setNewFeature", undefined);
        this.cancel();
        this.draw = {
          modifyDraw: undefined,
          snap: undefined,
        };

        this.$store.commit("setActiveTab", "Legenda");
        return;
      }

      this.$store.commit("activeTool", "Create" + type);

      if (this.$store.getters.infoClickState)
        this.$store.dispatch("toggleClickOff");
      this.$store.getters.getMap.removeInteraction(
        this.$store.getters.interaction
      );

      this.$store.commit("newInteraction", undefined);

      this.draw = {
        modifyDraw: undefined,
        snap: undefined,
      };

      this.$store.commit(
        "newInteraction",
        new Draw({
          source: this.drawLayer.getSource(),
          type,
          freehandCondition: shiftKeyOnly,
        })
      );
      this.$store.getters.interaction.on("drawstart", (event) => {
        this.drawLayer.getSource().clear();
        this.$store.commit("setNewFeature", undefined);
      });

      this.$store.getters.interaction.on("drawend", (event) => {
        this.$store.commit("setNewFeature", event.feature);
      });

      this.$store.getters.getMap.addInteraction(
        this.$store.getters.interaction
      );
    },

    processRequest(elementData) {
      const element = { ...elementData };

      element.organisation_id = this.organisation.id;
      element.created_by = this.user.id;
      element.element_type_id = element.element_type;
      if (this.user.owner_id) {
        element.owners = [this.user.owner_id];
      }

      if (this.organisation.id === 14) {
        element.organisation_id = 14;
        element.district_id = 25;
        element.created_by = 1;
        delete element.type;
        this.$store.getters.newFeature.unset("type");
      }

      let geom = this.$store.getters.newFeature.getGeometry();

      if (geom.getType() === "LineString") {
        let length = Math.round(geom.getLength());
        element.length = length;
      } else if (geom.getType() === "Polygon") {
        let area = Math.round(geom.getArea());
        element.area = area;
      }

      element.geom = new GeoJSON().writeFeatureObject(
        this.$store.getters.newFeature
      ).geometry;

      if (element.owners && element.owners.length > 0) {
        element.owner_ids = element.owners;
      }

      this.sendRequest(element).then((response) => {
        for (let layer of this.$store.getters.getMap.getLayers().array_) {
          if (typeof layer.getSource().clear === "function") {
            layer.getSource().clear();
          } else {
            layer.getSource().refresh();
          }
        }

        this.$store.commit("setActiveTab", "Legenda");
        this.$store.getters.getMap.removeInteraction(
          this.$store.getters.interaction
        );
        this.$store.commit("newInteraction", undefined);
        this.$store.commit("activeTool", undefined);
        if (!this.$store.getters.infoClickState)
          this.$store.dispatch("toggleClickOn");
      });
    },

    sendRequest(data) {
      return axios({
        method: "post",
        url: '/elementen/store/general',
        data,
      });
    },

    cancel() {
      this.drawLayer.getSource().clear();

      this.$store.commit("setActiveTab", "Legenda");
      this.$store.getters.getMap.removeInteraction(
        this.$store.getters.interaction
      );
      this.$store.commit("newInteraction", undefined);
      this.$store.commit("activeTool", undefined);
      if (!this.$store.getters.infoClickState)
        this.$store.dispatch("toggleClickOn");
    },
  },
  computed: {},
  mounted() {
    if (!this.elementsData) {
      console.error("elementsData is not provided.");
      return;
    }

    this.drawLayer = new VectorLayer({
      source: new VectorSource({
        wrapX: false,
      }),
    });

    if (this.$store.getters.newFeature) {
      try {
        this.drawLayer.getSource().addFeature(this.$store.getters.newFeature);
      } catch (error) {
        console.error("Feature is already added", error);
      }
    }

    this.drawLayer.setStyle(
      new Style({
        stroke: new Stroke({
          color: "rgb(0, 0, 0)",
          width: 2,
        }),
        image: new Circle({
          fill: new Fill({
            color: "rgb(0, 0, 0)",
          }),
          stroke: new Stroke({
            color: "rgb(0, 0, 0)",
            width: 1.25,
          }),
          radius: 5,
        }),
      })
    );

    this.draw.modifyDraw = new Modify({
      source: this.drawLayer.getSource(),
    });

    this.$store.getters.getMap.addInteraction(this.draw.modifyDraw);

    this.draw.snap = new Snap({
      source: this.drawLayer.getSource(),
    });

    this.$store.getters.getMap.addInteraction(this.draw.snap);
    this.$store.getters.getMap.addLayer(this.drawLayer);
  },
};
</script>

<style>
.card {
  padding-top: 10px;
  padding-bottom: 10px;
}

.createTools {
  position: fixed;
  right: 120px;
  top: 90px;
  z-index: 10;
}

div.button-row {
  margin: 1em;
}
</style>
