<template>
  <div class="col-md-10">
    <h3>Gebruikers</h3>

    <!-- New User Button -->
    <div class="row mb-3 align-items-center">
      <div class="col">
        <input
          type="text"
          v-model="search"
          @input="debouncedSearch"
          placeholder="Zoek op naam, gebruikersnaam, of email"
          class="form-control"
        />
      </div>
      <div class="col-auto">
        <Button
          label="Nieuwe gebruiker"
          icon="pi pi-plus"
          class="p-mb-3"
          @click="goToNewUser"
        />
      </div>
    </div>

    <DataTable
      :value="users"
      paginator
      sortMode="multiple"
      :lazy="true"
      :rows="pagination.per_page"
      :rowsPerPageOptions="[10, 20, 50, 100]"
      :totalRecords="pagination.total"
      @page="updatePagination"
      @sort="updateSorting"
      stripedRows
      :multiSortMeta="pagination.sort"
    >
      <Column
        field="first_name"
        header="Naam"
        style="width: 25%"
        sortable
      ></Column>
      <Column
        field="username"
        header="Gebruikersnaam"
        style="width: 25%"
        sortable
      ></Column>
      <Column field="email" header="Email" style="width: 25%" sortable></Column>
      <Column key="link" field="id" header="Bewerken" :sortable="false">
        <template v-slot:body="slotProps">
          <Button
            icon="pi pi-pencil"
            severity="success"
            variant="text"
            rounded
            aria-label="Bewerken"
            @click="$router.push('/beheer/users/' + slotProps.data.id)"
          ></Button>
        </template>
      </Column>
      <template #footer>
        <div
          class="d-flex justify-content-end align-items-center py-2 border-top bg-light"
        >
          <span class="fw-bold text-body-secondary">
            Totaal aantal gebruikers: {{ pagination.total }}
          </span>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import axios from "axios";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import _ from "lodash";

export default {
  name: "UserOverviewComponent",
  components: {
    DataTable,
    Column,
    Button,
  },
  data() {
    return {
      userTotal: 0,
      users: [],
      pagination: {
        current_page: 0,
        last_page: 1,
        per_page: 10,
        total: 0,
        sort: [],
      },
      search: "",
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    async getUsers() {
      try {
        const response = await axios.get("/beheerapi/users/list", {
          params: {
            page: this.pagination.current_page,
            per_page: this.pagination.per_page,
            search: this.search,
            sort: this.pagination.sort.map((s) => s.field).join(","),
            order: this.pagination.sort
              .map((s) => (s.order === -1 ? "desc" : "asc"))
              .join(","),
          },
        });

        const data = response.data;
        this.userTotal = data.total;
        this.users = data.data;

        this.pagination = {
          current_page: data.current_page,
          last_page: data.last_page,
          per_page: +data.per_page,
          total: data.total,
          sort: this.pagination.sort,
        };
      } catch (error) {
        console.error("Fout bij het ophalen van gebruikers:", error);
      }
    },

    updatePagination(event) {
      this.pagination.current_page = event.page + 1;
      this.pagination.per_page = event.rows;
      this.getUsers();
    },

    updateSorting(event) {
      if (event.multiSortMeta) {
        this.pagination.sort = event.multiSortMeta.map((sort) => ({
          field: sort.field,
          order: sort.order,
        }));
      }
      this.getUsers();
    },

    debouncedSearch: _.debounce(function () {
      this.pagination.current_page = 0;
      this.getUsers();
    }, 300),

    goToNewUser() {
      this.$router.push("/beheer/users/new");
    },
  },
};
</script>

<style>
.search-container {
  margin-bottom: 20px;
}

.search-container input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
