<template>
  <h5>
    Wis filters
    <Button
      icon="pi pi-times"
      severity="danger"
      variant="text"
      rounded
      aria-label="Cancel"
      @click="removeAllOption()"
    />
  </h5>
  <Accordion :value="openPanels" @update:value="updateOpenPanels" multiple>
    <AccordionPanel
      v-for="filter in filters"
      :key="filter.column_name"
      :value="filter.column_name"
    >
      <AccordionHeader>
        {{ filter.title }}
        <br />
      </AccordionHeader>
      <AccordionContent>
        <template v-if="filter?.type === 'date'">
          <DatePicker
            v-model="filter.date"
            showButtonBar
            dateFormat="yy-mm-dd"
            showIcon
            fluid
            iconDisplay="input"
            @date-select="selectDate($event, filter)"
          />
        </template>
        <template v-else>
          <template v-if="filter.options.length < 11">
            <p class="m-0" v-for="option in filter.options" :key="option.name">
              <Checkbox
                :name="option.name"
                v-model="option.checked"
                binary
                @value-change="selectCheckBox($event, filter)"
              />
              <label :for="option.name">
                {{ option.name }} ({{ option.count }})
              </label>
            </p>
          </template>

          <template v-else>
            <AutoComplete
              v-model="filter.searchValue"
              placeholder="Zoeken..."
              :suggestions="filteredOptions(filter)"
              @complete="search($event, filter)"
              @option-select="selectOption($event, filter)"
              dropdown
            />

            <!-- Toon Geselecteerde Opties -->
            <div v-if="filter.options?.length">
              <p
                class="m-0"
                v-for="selected in filter.options"
                :key="selected.name"
              >
                <template v-if="selected.checked">
                  {{ selected.name }} ({{ selected.count }})

                  <Button
                    style="height: 20px; width: 20px"
                    icon="pi pi-times"
                    severity="danger"
                    variant="text"
                    rounded
                    @click="removeOption(filter, selected)"
                  />
                </template>
              </p>
            </div>
          </template>
        </template>
      </AccordionContent>
    </AccordionPanel>
  </Accordion>
</template>

<script>
import { ref, watch } from "vue";
import Accordion from "primevue/accordion";
import AccordionPanel from "primevue/accordionpanel";
import AccordionHeader from "primevue/accordionheader";
import AccordionContent from "primevue/accordioncontent";
import AutoComplete from "primevue/autocomplete";
import DatePicker from "primevue/datepicker";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";

export default {
  props: {
    filters: {
      type: Array,
      required: true,
    },
  },
  components: {
    Accordion,
    AccordionContent,
    AccordionHeader,
    AccordionPanel,
    AutoComplete,
    DatePicker,
    Button,
    Checkbox,
  },
  emits: ["filter-change"],
  setup(props, { emit }) {
    const openPanels = ref([]);

    console.log(props.filters);

    watch(
      () => props.filters,
      (newFilters) => {
        if (newFilters && newFilters.length > 0) {
          newFilters.forEach((filter) => {
            if (filter.type === "date") {
              if (filter.options.date) {
                // Convert the date from Amsterdam time to UTC
                const localDate = new Date(filter.options.date);
                const utcDate = new Date(
                  Date.UTC(
                    localDate.getFullYear(),
                    localDate.getMonth(),
                    localDate.getDate(),
                    localDate.getHours(),
                    localDate.getMinutes(),
                    localDate.getSeconds()
                  )
                );

                // Format the UTC date as `yyyy-MM-dd`
                filter.date = utcDate.toISOString().split("T")[0];
              }
            } else {
              if (filter.options.some((option) => option.checked)) {
                openPanels.value.push(filter.column_name);
              }
              filter.searchValue = "";
            }
          });
        }
      },
      { immediate: true }
    );

    const updateOpenPanels = (value) => {
      openPanels.value = value;
    };

    const search = (event, filter) => {
      // Filter de opties
      if (!event.query.trim().length) {
        filter.filteredOptions = [...filter.options];
      } else {
        filter.filteredOptions = filter.options.filter((option) => {
          return option.name.toLowerCase().includes(event.query.toLowerCase());
        });
      }
    };
    const selectDate = (event, filter) => {
      // Convert the event (Amsterdam time) to a UTC date string
      const localDate = new Date(event); // Assuming `event` is in Amsterdam time
      const utcDate = new Date(
        Date.UTC(
          localDate.getFullYear(),
          localDate.getMonth(),
          localDate.getDate(),
          localDate.getHours(),
          localDate.getMinutes(),
          localDate.getSeconds()
        )
      );

      // Format the UTC date as `yyyy-MM-dd`
      const formattedUTCDate = utcDate.toISOString().split("T")[0];

      // Update the filter date with the formatted UTC date
      filter.date = formattedUTCDate;

      // Emit the updated filter
      emit("filter-change", filter);
    };

    const selectCheckBox = (event, filter) => {
      emit("filter-change", filter);
    };

    const selectOption = (event, filter) => {
      const option = filter.options.find((opt) => opt.name === event.value);
      option.checked = true;

      filter.searchValue = "";
      emit("filter-change", filter);
    };

    const filteredOptions = (filter) => {
      return (filter.filteredOptions || filter.options).map(
        (option) => option.name
      );
    };

    const removeOption = (filter, option) => {
      option.checked = false;
      emit("filter-change", filter);
    };

    const removeAllOption = async () => {
      props.filters.forEach((filter) => {
        if (filter.type === "date") {
          filter.date = undefined;
        } else {
          filter.options.forEach((option) => {
            option.checked = false;
          });
        }
      });
      emit("filter-change", []);
    };

    return {
      openPanels,
      // functies
      search,
      updateOpenPanels,
      filteredOptions,
      selectOption,
      selectCheckBox,
      selectDate,
      removeOption,
      removeAllOption,
    };
  },
};
</script>

<style scoped>
/* Stijlen specifiek voor filters */
</style>
