<template>
  <Toast />
  <div class="element-name">
    <div class="center-content">
      <h4 class="margin-bottom-0">
        <span class="e_element_arrows">
          <a
            v-if="previous && previous.id"
            :href="`/elementen/${previous.id}`"
            :title="`${previous.lsn} - ${previous.elementtype?.element_type || ''}`"
          >
            <span class="glyphicon glyphicon-chevron-left"></span>
          </a>
          <a
            v-if="next && next.id"
            :href="`/elementen/${next.id}`"
            :title="`${next.lsn} - ${next.elementtype?.element_type || ''}`"
          >
            <span class="glyphicon glyphicon-chevron-right"></span>
          </a>
        </span>
        LSN: {{ element.lsn }} - {{ element.elementtype?.element_type_nl || "" }}
      </h4>
    </div>

    <Tabs value="0">
      <TabList>
        <Tab value="0">Algemeen</Tab>
        <Tab value="1" @click="openMap">Kaart</Tab>
        <Tab value="2">Historie</Tab>
        <Tab value="3">Soorten</Tab>
        <Tab value="4">Bedreigingen</Tab>
        <Tab
          value="5"
          v-if="
            element.forms.length && checkAuthorization([
              'super',
              'upload_form',
              'download_form',
              'fill_form_info',
              'remove_form',
            ]) ||
            organisation.forms.length &&
            checkAuthorization([
              'super',
              'upload_form',
              'download_form',
              'fill_form_info',
              'remove_form',
            ])
          "
          >Formulieren</Tab
        >
        <Tab
          value="6"
          v-if="
            checkAuthorization(['super', 'admin', 'subsidies']) &&
            element.subsidy
          "
          >N.A.W.</Tab
        >
        <Tab value="7">Mutaties</Tab>
        <Tab
          value="8"
          v-if="checkAuthorization(['super', 'admin', 'add_new_user'])"
          >Niewe deelnemer</Tab
        >
      </TabList>
      <TabPanels>
        <div class="element element-single">
          <div class="row">
            <div class="col-md-6">
              <div class="panel panel-default">
                <div class="panel-body">
                  <general-component
                    :element="element"
                    :element_data="element_data"
                    :organisation="organisation"
                    :user="user"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="panel panel-default">
                <div class="panel-body">
                  <image-component :element="element"></image-component>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="panel panel-default">
                <div class="panel-body">
                  <TabPanel value="0" as="p" class="m-0">
                    <maintenance-component
                      :element="element"
                      :element_data="element_data"
                      :user="user"
                      :organisation="organisation"
                    />
                  </TabPanel>
                  <TabPanel value="1" as="p" class="m-0">
                    <!-- map -->
                  </TabPanel>
                  <TabPanel value="2" as="p" class="m-0">
                    <history-component
                      :element="element"
                      :element_data="element_data"
                      :user="user"
                      :organisation="organisation"
                    />
                  </TabPanel>
                  <TabPanel value="3" as="p" class="m-0">
                    <species-component
                      :element="element"
                      :user="user"
                      :organisation="organisation"
                      :species="species"
                    />
                  </TabPanel>
                  <TabPanel value="4" as="p" class="m-0">
                    <threats-component
                      :element="element"
                      :user="user"
                      :organisation="organisation"
                      :threats="threats"
                    />
                  </TabPanel>
                  <TabPanel
                    value="5"
                    as="p"
                    class="m-0"
                    v-if="
                      element.forms.length ||
                      organisation.forms.length
                    "
                  >
                    <forms-component v-if="checkAuthorization([
                        'super',
                        'upload_form',
                        'download_form',
                        'fill_form_info',
                        'remove_form',
                      ])" 
                      :element="element"
                      :organisation="organisation"
                      :files="files"
                      :filled_forms="filled_forms"
                    />
                  </TabPanel>
                  <TabPanel
                    value="6"
                    as="p"
                    class="m-0"
                    v-if="
                      checkAuthorization(['super', 'admin', 'subsidies']) &&
                      element.subsidy
                    "
                  >
                    <subsidy-component :element="element" />
                  </TabPanel>
                  <TabPanel value="7" as="p" class="m-0">
                    <mutations-component
                      :element="element"
                    />
                  </TabPanel>
                  <TabPanel
                    value="8"
                    as="p"
                    class="m-0"
                    v-if="
                      checkAuthorization(['super', 'admin', 'add_new_user'])
                    "
                  >
                    <create-component
                      :element="element"
                      :element_history="element_history"
                    />
                  </TabPanel>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="panel panel-default">
                  <div class="panel-body">
                    <overview-map
                      :features="features"
                      :organisation="organisation"
                      :user="user"
                    ></overview-map>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TabPanel
            value="0"
            as="p"
            class="m-0"
            v-if="organisation.show_menu_brief"
          >
            <div class="row">
              <div class="col-md-6">
                <div class="panel panel-default">
                  <div class="panel-body">
                    <files-component
                      :element="element"
                      :organisation="organisation"
                    ></files-component>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </div>
      </TabPanels>
    </Tabs>
  </div>
</template>

<script>
import OverviewMap from "./OverviewMap.vue";
import { ref, onMounted, computed, provide } from "vue";
import GeneralComponent from "./GeneralComponent.vue";
import ImageComponent from "./ImageComponent.vue";
import SpeciesComponent from "./SpeciesComponent.vue";
import MaintenanceComponent from "./MaintenanceComponent.vue";
import ThreatsComponent from "./ThreatsComponent.vue";
import HistoryComponent from "./HistoryComponent.vue";
import FormsComponent from "./FormsComponent.vue";
import MutationsComponent from "./MutationsComponent.vue";
import SubsidyComponent from "./SubsidyComponent.vue";
import CreateComponent from "./CreateComponent.vue";

import Tabs from "primevue/tabs";
import TabList from "primevue/tablist";
import Tab from "primevue/tab";
import TabPanels from "primevue/tabpanels";
import TabPanel from "primevue/tabpanel";
import FilesComponent from "./FilesComponent.vue";
import Toast from 'primevue/toast';

export default {
  components: {
    GeneralComponent,
    ImageComponent,
    MaintenanceComponent,
    SpeciesComponent,
    ThreatsComponent,
    HistoryComponent,
    FormsComponent,
    MutationsComponent,
    SubsidyComponent,
    CreateComponent,
    OverviewMap,
    FilesComponent,
    Tabs,
    TabList,
    Tab,
    TabPanel,
    TabPanels,
    Toast,
  },
  props: {
    element: Object,
    previous: Object,
    next: Object,
    organisation: Object,
    features: Object,
    element_data: Object,
    element_history: Object,
    user: Object,
    species: Object,
    threats: Object,
    files: Object,
    filled_forms: Object,
  },
  setup(props) {
    const showForms = computed(
      () => props.element.forms?.length || props.organisation.forms?.length
    );
    const showSubsidyTab = computed(
      () => props.element.subsidy && props.element.subsidy.length
    );

    // Function to handle tab switching
    const activateTab = (hash) => {
      const tabLink = document.querySelector(`a[href="${hash}"]`);
      if (tabLink) tabLink.click();
    };

    onMounted(() => {
      // console.log(this.element)
      if (window.location.hash) activateTab(window.location.hash);
    });

    const openMap = () => {
      window.open('/kaart/elementen/' + props.element.id, '_blanc')
    }

    const checkAuthorization = (perms) => {
      for (const perm of perms) {
        for (const role of props.user.roles) {
          if (role.permissions.includes(perm)) {
            return true;
          }
        }
      }
      return false;
    };

    // Maak de checkAuthorization-functie beschikbaar via provide
    provide("checkAuthorization", checkAuthorization);

    return {
      showForms,
      user: props.user,
      showSubsidyTab,
      checkAuthorization,
      openMap
    };
  },
};
</script>

<style scoped>
/* .center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

div.p-tablist-tab-list {
  justify-content: center !important;
} */
</style>
