<template>
  <form>
    <fieldset>
      <h3>Element maken</h3>

      <div class="row">
        <div class="col-sm-12">
          <label for="element_type">Elementtype</label>
          <select
            v-model="element.element_type"
            name="element_type"
            required
            class="form-control"
          >
            <option
              v-for="option in elementsData?.element_typesArr"
              :key="option.id"
              :value="option.id"
            >
              {{ option.element_type_nl }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <label for="shape_id">Vorm</label>
          <select
            v-model="element.shape_id"
            name="shape_id"
            class="form-control"
          >
            <option
              v-for="option in elementsData?.shapesArr"
              :key="option.id"
              :value="option.id"
            >
              {{ option.shape_nl }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <label for="location_id">Locatie</label>
          <select
            v-model="element.location_id"
            name="location_id"
            class="form-control"
          >
            <option
              v-for="option in elementsData?.locationsArr"
              :key="option.id"
              :value="option.id"
            >
              {{ option.location }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <label for="objective_id">Streefbeeld</label>
          <select
            v-model="element.objective_id"
            name="objective_id"
            class="form-control"
          >
            <option
              v-for="option in elementsData?.objectivesArr"
              :key="option.id"
              :value="option.id"
            >
              {{ option.objective_nl }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <label for="diameter_id">Diameter</label>
          <select
            v-model="element.diameter_id"
            name="diameter_id"
            class="form-control"
          >
            <option
              v-for="option in elementsData?.diametersArr"
              :key="option.id"
              :value="option.id"
            >
              {{ option.diameter }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <label for="cover">Bedekkingsgraad</label>
          <select v-model="element.cover" name="cover" class="form-control">
            <option :value="0">N.v.t.</option>
            <option :value="10">0 - 10%</option>
            <option :value="20">10 - 20%</option>
            <option :value="30">20 - 30%</option>
            <option :value="40">30 - 40%</option>
            <option :value="50">40 - 50%</option>
            <option :value="60">50 - 60%</option>
            <option :value="70">60 - 70%</option>
            <option :value="80">70 - 80%</option>
            <option :value="90">80 - 90%</option>
            <option :value="100">90 - 100%</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <label for="owners">Eigenaren</label>
          <select
            v-model="element.owners"
            name="owners"
            multiple
            class="form-control"
          >
            <option
              v-for="option in elementsData?.ownersArr"
              :key="option.id"
              :value="option.id"
            >
              {{ option.owner }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <label for="number_in_row">Aantal</label>
          <input
            type="number"
            v-model="element.number_in_row"
            name="number_in_row"
            class="form-control"
          />
        </div>
      </div>

      <div class="button-row">
        <button type="button" class="btn btn-default" @click="cancel">
          Cancel
        </button>
        <button
          type="button"
          @click="handleSubmit"
          class="btn btn-success pull-right"
        >
          Opslaan
        </button>
      </div>
    </fieldset>
  </form>
</template>

<script>
export default {
  props: {
    element: {
      type: Object,
      required: true,
    },
    elementsData: {
      type: Object,
      required: true,
    },
    organisation: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("submit", this.element);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
.row {
  margin-bottom: 15px;
}

.button-row {
  margin-top: 15px;
}
</style>
