<template>
  <div>
    <div class="tools pt-1 pb-1">
      <div class="btn-toolbar">
        <div class="btn-group gpsTool">
          <button
            v-on:click="toggleGPS()"
            :class="[
              'btn btn-default',
              { active: this.$store.getters.activeTool === 'geolocation' },
            ]"
            type="button"
            data-toggle="tooltip"
            data-placement="bottom"
            title="GPS Inschakelen"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              class="bi bi-geo-alt"
              viewBox="0 0 16 16"
            >
              <path
                d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"
              />
              <path
                d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
              />
            </svg>
          </button>
        </div>
        <div class="btn-group">
          <button
            v-on:click="multiInfo('Polygon')"
            :class="[
              'btn btn-default',
              { active: this.$store.getters.activeTool === 'infoPolygon' },
            ]"
            type="button"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Polygoon selectie"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              class="bi bi-info-square"
              viewBox="0 0 16 16"
            >
              <path
                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
              />
              <path
                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
              />
            </svg>
          </button>
          <button
            v-on:click="multiInfo('Circle')"
            :class="[
              'btn btn-default',
              { active: this.$store.getters.activeTool === 'infoCircle' },
            ]"
            type="button"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Cirkel selectie"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              class="bi bi-info-circle"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
              />
              <path
                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
              />
            </svg>
          </button>
        </div>
        <div class="btn-group">
          <button
            v-on:click="redline('LineString')"
            :class="[
              'btn btn-default',
              {
                active: this.$store.getters.activeTool === 'redlineLineString',
              },
            ]"
            type="button"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Lijn tekenen"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              class="bi bi-pencil-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
              />
            </svg>
          </button>
          <button
            v-on:click="redline('Polygon')"
            :class="[
              'btn btn-default',
              { active: this.$store.getters.activeTool === 'redlinePolygon' },
            ]"
            type="button"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Polygoon tekenen"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              class="bi bi-pencil-square"
              viewBox="0 0 16 16"
            >
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
              />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
          </button>
          <!-- 
                <button v-on:click="redline('Circle')" :class="['btn btn-default', { active: this.$store.getters.activeTool === 'redlineCircle' }]" type="button" data-toggle="tooltip" data-placement="bottom" title="Cirkel tekenen">
                    <span class="glyphicon glyphicon-record" aria-hidden="true"></span>
                </button>
                -->
          <button
            v-on:click="deleteRedline()"
            class="btn btn-default"
            type="button"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Verwijder redlining"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              class="bi bi-eraser-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm.66 11.34L3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z"
              />
            </svg>
          </button>
        </div>
        <div class="btn-group gpsTool">
          <button
            v-on:click="zoomToExtent()"
            :class="['btn btn-default']"
            type="button"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Zoom naar start kaartbeeld"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              class="bi bi-arrows-fullscreen"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z"
              />
            </svg>
          </button>
        </div>
        <div class="btn-group gpsTool">
          <button
            v-on:click="getCoordinate()"
            :class="[
              'btn btn-default',
              { active: this.$store.getters.activeTool === 'getcoordinate' },
            ]"
            type="button"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Klik op de kaart voor coordinaten"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              class="bi bi-geo-fill"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"
              />
            </svg>
          </button>
        </div>
        <div class="btn-group">
          <button
            v-on:click="measuring('LineString')"
            :class="[
              'btn btn-default',
              {
                active: this.$store.getters.activeTool === 'measureLineString',
              },
            ]"
            type="button"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Lengte meten"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              class="bi bi-rulers"
              viewBox="0 0 16 16"
            >
              <path
                d="M1 0a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h5v-1H2v-1h4v-1H4v-1h2v-1H2v-1h4V9H4V8h2V7H2V6h4V2h1v4h1V4h1v2h1V2h1v4h1V4h1v2h1V2h1v4h1V1a1 1 0 0 0-1-1H1z"
              />
            </svg>
          </button>
          <button
            v-on:click="measuring('Polygon')"
            :class="[
              'btn btn-default',
              { active: this.$store.getters.activeTool === 'measurePolygon' },
            ]"
            type="button"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Oppervlakte meten"
          >
            <svg height="14" width="14">
              <text x="0" y="15" font-size="12" fill="currentColor">m²</text>
            </svg>
          </button>
          <button
            v-on:click="clearMeaureLayer()"
            class="btn btn-default"
            type="button"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Verwijder meetlijnen"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              class="bi bi-eraser-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm.66 11.34L3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

import Draw from "ol/interaction/Draw";
import { Snap, Modify, Select } from "ol/interaction";
import { shiftKeyOnly } from "ol/events/condition";
import GeoJSON from "ol/format/GeoJSON";
import { Circle, Text, Fill, Stroke, Style, Icon } from "ol/style";
import Geolocation from "ol/Geolocation";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { containsExtent, Extent } from "ol/extent";
import { unByKey } from "ol/Observable";
import { fromCircle } from "ol/geom/Polygon";
import { boundingExtent } from "ol/extent";
import Overlay from "ol/Overlay.js";
import { Polygon, LineString } from "ol/geom";

// Some icons
import { SquareIcon, CircleIcon } from "vue-feather-icons";
import Vue from "vue";
// import Toasted from 'vue-toasted';
// Vue.use(Toasted);

export default {
  name: "ToolsComponent",
  components: {
    SquareIcon,
    CircleIcon,
  },
  props: {
    organisation: Object,
  },
  data() {
    return {
      geolocation: undefined,
      positionFeature: undefined,
      overlayLayer: undefined,
      draw: {
        modifyDraw: undefined,
        snap: undefined,
      },
      drawLayer: undefined,
      measure: {
        modify: undefined,
        snap: undefined,
      },
      measureLayer: undefined,
      redlineText: "",
      measureTooltipElement: undefined,
      measureTooltip: undefined,
      sketch: undefined,
    };
  },
  mounted() {
    var source = new VectorSource({ wrapX: false });

    this.overlayLayer = new VectorLayer({
      source: source,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 186, 25, 0.5)",
        }),
        stroke: new Stroke({
          color: "#ffba19",
          width: 5,
        }),
        image: new Circle({
          radius: 7,
          fill: new Fill({
            color: "rgba(255, 186, 25, 0.5)",
          }),
          stroke: new Stroke({
            color: "#ffba19",
            width: 5,
          }),
        }),
      }),
    });

    this.drawLayer = new VectorLayer({
      title: "DrawLayer",
      source: new VectorSource({
        wrapX: false,
      }),
    });

    this.drawLayer.setStyle((feature) => {
      return new Style({
        stroke: new Stroke({
          color: "rgb(29, 1, 139)",
          width: 2,
        }),
        fill: new Fill({
          color: "rgba(247, 113, 73, 0.4)",
        }),
        text: new Text({
          text: feature.get("text") ? feature.get("text") : "",
          font: "30px sans-serif",
          fill: new Fill({
            color: "#000",
          }),
          stroke: new Stroke({
            color: "#fff",
            width: 10,
          }),
        }),
      });
    });

    this.measureLayer = new VectorLayer({
      title: "DrawLayer",
      source: new VectorSource(),
    });

    this.measureLayer.setStyle((feature) => {
      return new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "#ffcc33",
          width: 2,
        }),
        image: new Circle({
          radius: 7,
          fill: new Fill({
            color: "#ffcc33",
          }),
        }),
        text: new Text({
          text: feature.get("text") ? feature.get("text") : "",
          textAlign: "center",
          offsetY: 40,
          scale: 1.4,
          overflow: true,
          stroke: new Stroke({
            color: "#ffcc33",
            width: 3,
          }),
          fill: new Fill({
            color: "#000",
          }),
        }),
      });
    });

    this.$store.getters.getMap.addLayer(this.overlayLayer);
    this.$store.getters.getMap.addLayer(this.measureLayer);
    this.$store.getters.getMap.addLayer(this.drawLayer);
  },
  methods: {
    toggleGPS() {
      if (this.$store.getters.activeTool !== "geolocation") {
        this.$store.commit("activeTool", "geolocation");
      } else {
        this.$store.commit("activeTool", undefined);
        this.removeInteractions();
      }

      if (!this.geolocation) {
        this.geolocation = new Geolocation({
          projection: this.$store.getters.getMap.getView().getProjection(),
          trackingOptions: {
            enableHighAccuracy: true,
            maximumAge: 2000,
          },
        });
      }

      this.geolocation.setTracking(
        this.$store.getters.activeTool === "geolocation"
      );

      if (this.$store.getters.activeTool === "geolocation") {
        // Create a position feature to show where the user is located
        this.positionFeature = new Feature();
        this.positionFeature.setStyle(
          new Style({
            image: new Circle({
              radius: 6,
              fill: new Fill({
                color: "#3399CC",
              }),
              stroke: new Stroke({
                color: "#fff",
                width: 2,
              }),
            }),
          })
        );

        // Get the coordinates from the geolocation object, add them to the feature and zoom to it
        this.geolocation.on("change:position", () => {
          var coordinates = this.geolocation.getPosition();
          this.positionFeature.setGeometry(
            coordinates ? new Point(coordinates) : null
          );

          // Only move the map if we are 70% out of the center
          let mapSize = this.$store.getters.getMap.getSize();
          let mapExtent = this.$store.getters.getMap
            .getView()
            .calculateExtent(mapSize);
          let mapCenter = this.$store.getters.getMap.getView().getCenter();

          const leftRightBound = (mapExtent[0] - mapCenter[0]) * 0.3;
          const topBottomBound = (mapExtent[1] - mapCenter[1]) * 0.3;

          const safeMapExtent = [
            mapExtent[0] - leftRightBound,
            mapExtent[1] - topBottomBound,
            mapExtent[2] + leftRightBound,
            mapExtent[3] + topBottomBound,
          ];

          if (
            !containsExtent(
              safeMapExtent,
              this.positionFeature.getGeometry().getExtent()
            )
          ) {
            this.$store.getters.getMap
              .getView()
              .animate({ center: coordinates });
          }
        });

        this.overlayLayer.getSource().addFeature(this.positionFeature);
      } else {
        // Remove the position feature when toggling
        this.overlayLayer.getSource().removeFeature(this.positionFeature);
        this.positionFeature = undefined;
        // this.geolocation = undefined;
      }
    },

    redline(type) {
      if (this.$store.getters.infoClickState)
        this.$store.dispatch("toggleClickOff");

      if ("redline" + type === this.$store.getters.activeTool) {
        // Turn the info click back on if it was turned off
        if (!this.$store.getters.infoClickState)
          this.$store.dispatch("toggleClickOn");
        this.$store.getters.getMap.removeInteraction(
          this.$store.getters.interaction
        );
        this.draw = {
          modifyDraw: undefined,
          snap: undefined,
        };
        if (this.$store.getters.activeTool === "geolocation")
          this.$store.commit("activeTool", undefined);
        return;
      }

      // Clear previous drawings
      // this.$store.getters.getMap.removeInteraction(this.draw.modifyDraw);
      // this.$store.getters.getMap.removeInteraction(this.draw.snap);
      this.removeInteractions();
      this.$store.commit("newInteraction", undefined);

      this.draw = {
        modifyDraw: undefined,
        snap: undefined,
      };

      this.$store.commit("activeTool", "redline" + type);

      this.$store.commit(
        "newInteraction",
        new Draw({
          source: this.drawLayer.getSource(),
          type: /** @type {ol.geom.GeometryType} */ (type),
          freehandCondition: shiftKeyOnly,
        })
      );

      this.draw.modifyDraw = new Modify({
        source: this.drawLayer.getSource(),
      });

      // And add the interaction to the map
      this.$store.getters.getMap.addInteraction(this.draw.modifyDraw);
      this.$store.getters.getMap.addInteraction(
        this.$store.getters.interaction
      );
      // Create a snap interaction
      this.draw.snap = new Snap({
        source: this.drawLayer.getSource(),
      });
      // And add the interaction to the map
      this.$store.getters.getMap.addInteraction(this.draw.snap);

      this.$store.getters.interaction.on("drawend", (event) => {
        const feature = event.feature;
        //     this.$modal.show(
        //     {
        //     template: `
        //     <div class="container-fluid">
        //         <h3>Redline tekst</h3>
        //         <input id="redlinetxt" value='' placeholder="Vul de titel hier in" />
        //         <button class="btn btn-success pull-right" @click="$emit('close')">Opslaan</button>
        //     </div>
        //     `,
        //     props: ['text']
        //     },
        //     { text: this.redlineText},
        //     { height: '120px' },
        //     { 'before-close': event => {
        //         this.redlineText = document.getElementById('redlinetxt').value;
        //         feature.set('text', this.redlineText);
        //     } },
        // )
      });
    },

    multiInfo(action) {
      if ("info" + action === this.$store.getters.activeTool) {
        console.log("turn multi info off " + action);
        if (!this.$store.getters.infoClickState)
          this.$store.dispatch("toggleClickOn");
        this.removeInteractions();
        this.$store.commit("activeTool", undefined);

        this.feature = undefined;
        this.draw = {
          modifyDraw: undefined,
          snap: undefined,
        };

        return;
      }

      this.$store.commit("activeTool", "info" + action);

      if (this.$store.getters.infoClickState)
        this.$store.dispatch("toggleClickOff");
      console.log(this.$store.getters.infoClickState);
      this.$store.getters.getMap.removeInteraction(
        this.$store.getters.interaction
      );

      this.$store.commit("newInteraction", undefined);

      this.$store.commit(
        "newInteraction",
        new Draw({
          type: action,
        })
      );

      this.$store.getters.getMap.addInteraction(
        this.$store.getters.interaction
      );

      this.$store.getters.interaction.on("drawend", (evt) => {
        if (action === "Circle") {
          evt.feature.setGeometry(fromCircle(evt.feature.getGeometry()));
        }
        this.$store.commit(
          "clickEvent",
          new GeoJSON().writeFeatureObject(evt.feature).geometry
        );
        this.$store.commit("setActiveTab", "Info");
      });
    },
    zoomToExtent() {
      let extent = this.organisation.map_config.extent;
      console.log(extent);
      if (extent.constructor === Array) {
        extent = boundingExtent(extent);
      } else {
        const geojson = new GeoJSON();
        const feature = geojson.readFeature(extent);
        extent = feature.getGeometry().getExtent();
      }

      this.$store.getters.getMap
        .getView()
        .fit(extent, this.$store.getters.getMap.getSize());
    },
    deleteRedline() {
      this.$store.commit("activeTool", undefined);
      this.removeInteractions();
      this.drawLayer.getSource().clear();
      this.draw = {
        interaction: undefined,
        modifyDraw: undefined,
        snap: undefined,
      };
      if (!this.$store.getters.infoClickState)
        this.$store.dispatch("toggleClickOn");
    },

    getCoordinate() {
      if (this.$store.getters.activeTool !== "getcoordinate") {
        if (this.$store.getters.infoClickState)
          this.$store.dispatch("toggleClickOff");
        this.$store.commit("activeTool", "getcoordinate");

        this.removeInteractions();

        this.$store.commit(
          "newInteraction",
          this.$store.getters.getMap.on("singleclick", (event) => {
            if (this.$store.getters.activeTool === "getcoordinate") {
              console.log("get coordiante click");
              const x = Math.round(event.coordinate[0] * 100) / 100;
              const y = Math.round(event.coordinate[1] * 100) / 100;
              const coordinate = x + " , " + y;
              this.$toasted.show(coordinate);
            }
          })
        );
      } else {
        console.log("tool turned off");
        this.$store.commit("activeTool", undefined);
        this.removeInteractions();

        if (!this.$store.getters.infoClickState)
          this.$store.dispatch("toggleClickOn");
      }
    },

    measuring(format) {
      if (this.$store.getters.infoClickState)
        this.$store.dispatch("toggleClickOff");

      if ("measure" + format === this.$store.getters.activeTool) {
        // Turn the info click back on if it was turned off
        if (!this.$store.getters.infoClickState)
          this.$store.dispatch("toggleClickOn");
        this.removeInteractions();
        this.measure = {
          modify: undefined,
          snap: undefined,
        };
        if (this.$store.getters.activeTool === "measure" + format)
          this.$store.commit("activeTool", undefined);
        return;
      }
      // Clear previous drawings
      // this.$store.getters.getMap.removeInteraction(this.draw.modifyDraw);
      // this.$store.getters.getMap.removeInteraction(this.draw.snap);
      this.removeInteractions();

      this.measure = {
        modify: undefined,
        snap: undefined,
      };

      this.$store.commit("activeTool", "measure" + format);

      this.$store.commit(
        "newInteraction",
        new Draw({
          source: this.measureLayer.getSource(),
          type: /** @type {ol.geom.GeometryType} */ (format),
          freehandCondition: shiftKeyOnly,
        })
      );

      // this.measure.modify = new Modify({
      //     source: this.drawLayer.getSource()
      // });

      // And add the interaction to the map
      // this.$store.getters.getMap.addInteraction(this.measure.modify);
      this.$store.getters.getMap.addInteraction(
        this.$store.getters.interaction
      );

      var output;
      this.createMeasureTooltip();
      let listener;

      this.$store.getters.interaction.on("drawstart", (event) => {
        this.sketch = event.feature;
        let tooltipCoord = event.coordinate;
        listener = this.sketch.getGeometry().on("change", (evt) => {
          var geom = evt.target;

          if (geom instanceof Polygon) {
            var area = geom.getArea();

            if (area != null) {
              output = Math.round(area * 100) / 100 + " " + "m2";
            }
          } else if (geom instanceof LineString) {
            var length = Math.round(geom.getLength() * 100) / 100;

            if (length != null) {
              output = Math.round(length * 100) / 100 + " " + "m";
            }
          }
          this.measureTooltipElement.innerHTML = output;
          this.measureTooltip.setPosition(tooltipCoord);
        });
      });

      this.$store.getters.interaction.on("drawend", (event) => {
        this.sketch = null;
        event.feature.set("text", output);

        // Remove this tooltip now we have the label set
        this.measureTooltipElement.parentNode.removeChild(
          this.measureTooltipElement
        );

        this.measureTooltipElement = null;

        this.createMeasureTooltip();
        unByKey(listener);
      });

      // Create a snap interaction
      // this.measure.snap = new Snap({
      //     source: this.drawLayer.getSource()
      // });

      // And add the interaction to the map
      // this.$store.getters.getMap.addInteraction(this.measure.snap);
    },

    clearMeaureLayer() {
      this.measureLayer.getSource().clear();
    },

    createMeasureTooltip() {
      if (this.measureTooltipElement) {
        this.measureTooltipElement.parentNode.removeChild(
          this.measureTooltipElement
        );
      }
      const positioning = "bottom-center";
      this.measureTooltipElement = document.createElement("div");
      this.measureTooltipElement.className = "tooltip tooltip-measure";
      this.measureTooltip = new Overlay({
        element: this.measureTooltipElement,
        offset: [0, -15],
        positioning,
      });

      this.$store.getters.getMap.addOverlay(this.measureTooltip);
    },

    removeInteractions() {
      if (this.$store.getters.interaction) {
        this.$store.getters.getMap.removeInteraction(
          this.$store.getters.interaction
        );

        // for info click
        if (this.$store.getters.interaction.listener) {
          this.$store.getters.getMap.un(
            this.$store.getters.interaction.type,
            this.$store.getters.interaction.listener
          );
        }

        // Make sure we have a undefined value instead of an empty object {}
        this.$store.commit("newInteraction", undefined);
      }
    },
  },
};
</script>

<style>
.tools {
  padding-top: 55px;
  padding-bottom: 10px;
  position: absolute;
  height: 110px;
  width: 60px;
  right: 45px;
  top: 35px;
  z-index: 10;
}

button {
  outline: none !important;
}

/** Tooltip Styling **/
.tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
}
.tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.tooltip-static {
  background-color: rgb(255, 172, 69);
  color: black;
  border: 1px solid white;
}
.tooltip-measure:before,
.tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.tooltip-static:before {
  border-top-color: rgb(255, 172, 69);
}
</style>
