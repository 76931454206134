<template>
  <div class="p-4">
      <h3>NAW deelnemer</h3>

      <div class="row">
          <div class="col-md-4 title">Werkgebied</div>
          <div class="col-md-8">
              {{ element.subsidy?.workarea }}
          </div>
      </div>
      <div class="row">
          <div class="col-md-4 title">Beheerpakket</div>
          <div class="col-md-8">
              {{ element.subsidy?.id }}
          </div>
      </div>
      <div class="row">
          <div class="col-md-4 title">Beheerpakket code</div>
          <div class="col-md-8">
              {{ element.subsidy?.pakketcode }}
          </div>
      </div>
      <div class="row">
          <div class="col-md-4 title">Beheerpakket groep</div>
          <div class="col-md-8">
              {{ element.subsidy?.pakketgroep }}
          </div>
      </div>
      <div class="row">
          <div class="col-md-4 title">Label</div>
          <div class="col-md-8">
              {{ element.subsidy?.label }}
          </div>
      </div>
      <div class="row">
          <div class="col-md-4 title">Naam deelnemer</div>
          <div class="col-md-8">
              {{ element.subsidy?.deeln_naam }}
          </div>
      </div>
      <div class="row">
          <div class="col-md-4 title">Start Periode</div>
          <div class="col-md-8">
              {{ element.subsidy?.periodstrt }}
          </div>
      </div>
      <div class="row">
          <div class="col-md-4 title">Einde Periode</div>
          <div class="col-md-8">
              {{ element.subsidy?.periodend }}
          </div>
      </div>
      <div class="row">
          <div class="col-md-4 title">Afdeling</div>
          <div class="col-md-8">
              {{ element.subsidy?.afdeling }}
          </div>
      </div>
      <div class="row">
          <div class="col-md-4 title">Straatnaam</div>
          <div class="col-md-8">
              {{ element.subsidy?.deeln_stra }}
          </div>
      </div>
      <div class="row">
          <div class="col-md-4 title">Woonplaats</div>
          <div class="col-md-8">
              {{ element.subsidy?.deeln_woon }}
          </div>
      </div>
      <div class="row">
          <div class="col-md-4 title">Postcode</div>
          <div class="col-md-8">
              {{ element.subsidy?.deeln_post }}
          </div>
      </div>
      <div class="row">
          <div class="col-md-4 title">Email</div>
          <div class="col-md-8">
              {{ element.subsidy?.deeln_emai }}
          </div>
      </div>
      <div class="row">
          <div class="col-md-4 title">Tel. nummer</div>
          <div class="col-md-8">
              {{ element.subsidy?.deeln_tel }}
          </div>
      </div>
      <div class="row">
          <div class="col-md-4 title">Jaar</div>
          <div class="col-md-8">
              {{ element.subsidy?.year }}
          </div>
      </div>


      <Divider />

      <subsidy-contact-component
        :element="element"
      ></subsidy-contact-component>

      <!-- <h5>Notities</h5>
      <div>
        <Textarea
          v-model="note"
          rows="5"
          cols="80"
          placeholder="Add a note..."
          class="p-mb-2"
        />
        <Button
          label="Save Note"
          icon="pi pi-save"
          class="p-button-primary"
          @click="submitNote"
        />
      </div>

      <Message v-if="success" severity="success" :closable="false" class="p-mt-3">
        {{ success }}
      </Message> -->
  </div>
</template>

<script>
import axios from 'axios';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import Divider from 'primevue/divider';
import Message from 'primevue/message';
import SubsidyContactComponent from './SubsidyContactComponent.vue';

export default {
  components: {
    Card,
    Button,
    Textarea,
    Divider,
    Message,
    SubsidyContactComponent
  },
  data() {
    return {
      note: '',
      success: null,
    };
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async submitNote() {
      try {
        await axios.post('/note/create', {
          element_id: this.element.id,
          subsidy_id: this.element.subsidy.id,
          note: this.note,
        });
        this.success = 'Note saved successfully!';
        this.note = '';
      } catch (error) {
        console.error('Error saving note:', error);
      }
    },
  },
};
</script>

<style scoped>
.font-bold {
  font-weight: bold;
}
.p-grid {
  margin-bottom: 1rem;
}
</style>
