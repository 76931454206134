<template>
  <Card class="col-md-10 mx-auto">
    <template #title>
      <h2>
        {{ isEditMode ? "Gebruiker Bewerken" : "Nieuwe Gebruiker Toevoegen" }}
      </h2>
    </template>

    <template #content>
      <form @submit.prevent="saveUser">
        <!-- Username -->
        <div class="form-group mb-3">
          <label for="username">Gebruikersnaam</label>
          <InputText
            id="username"
            v-model="form.username"
            class="form-control"
            required
          />
          <div v-if="errors.username" class="text-danger">
            {{ errors.username }}
          </div>
        </div>
        <!-- First Name -->
        <div class="form-group mb-3">
          <label for="first_name">Naam</label>
          <InputText
            id="first_name"
            v-model="form.first_name"
            class="form-control"
            required
          />
          <div v-if="errors.first_name" class="text-danger">
            {{ errors.first_name }}
          </div>
        </div>
        <!-- Last Name -->
        <div class="form-group mb-3">
          <label for="last_name">Achternaam</label>
          <InputText
            id="last_name"
            v-model="form.last_name"
            class="form-control"
            required
          />
          <div v-if="errors.last_name" class="text-danger">
            {{ errors.last_name }}
          </div>
        </div>
        <!-- Email -->
        <div class="form-group mb-3">
          <label for="email">E-mail</label>
          <InputText
            id="email"
            v-model="form.email"
            class="form-control"
            required
          />
          <div v-if="errors.email" class="text-danger">{{ errors.email }}</div>
        </div>
        <!-- Company AutoComplete (optioneel) -->
        <div class="mb-3">
          <label for="company">Bedrijf</label>
          <AutoComplete
            id="company"
            v-model="form.company"
            optionLabel="executed_by"
            dropdown
            :suggestions="companySuggestions"
            @complete="searchCompanies"
            class="form-control"
            placeholder="Selecteer een bedrijf"
          />
        </div>
        <!-- Roles AutoComplete -->
        <div class="mb-3">
          <label for="roles">Rollen</label>
          <AutoComplete
            id="roles"
            v-model="form.roles"
            optionLabel="name"
            multiple
            dropdown
            :suggestions="roleSuggestions"
            @complete="searchRoles"
            class="form-control"
          />
          <div v-if="errors.roles" class="text-danger">{{ errors.roles }}</div>
        </div>
        <!-- Owner AutoComplete (optioneel) -->
        <div class="mb-3">
          <label for="owner">Eigenaar (optioneel)</label>
          <AutoComplete
            id="owner"
            v-model="form.owner"
            optionLabel="owner"
            dropdown
            :suggestions="ownerSuggestions"
            @complete="searchOwners"
            class="form-control"
            placeholder="Kies een eigenaar (optioneel)"
          />
        </div>
      </form>
    </template>

    <template #footer>
      <div class="d-flex justify-content-between mt-2">
        <Button label="Annuleren" class="p-button-secondary" @click="cancel" />
        <Button
          type="button"
          :label="isEditMode ? 'Bijwerken' : 'Toevoegen'"
          class="p-button-success"
          :disabled="!isFormValid"
          @click="saveUser"
        />
      </div>
    </template>
  </Card>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import AutoComplete from "primevue/autocomplete";
import Card from "primevue/card";
import axios from "axios";

export default {
  name: "UserFormComponent",
  components: {
    InputText,
    Button,
    AutoComplete,
    Card,
  },
  props: {
    organisation: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    // Get companies from the organisation prop.
    const companies = [
      { id: null, executed_by: "Geen bedrijf" },
      ...(props.organisation?.companies || []),
    ];

    const roleSuggestions = ref([]);
    const companySuggestions = ref([]);
    const ownerSuggestions = ref([]);
    const allOwners = ref([]);
    const allRoles = ref([]);

    // Define the form model.
    const form = ref({
      id: null,
      username: "",
      first_name: "",
      last_name: "",
      email: "",
      roles: [],
      company: companies[0],
      owner: null,
    });

    // Object to hold field errors.
    const errors = ref({});
    const isEditMode = ref(false);

    // Basic email validation function.
    const validEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    };

    // Manual validation to show error messages.
    const validateForm = () => {
      let valid = true;
      errors.value = {}; // Reset errors

      if (!form.value.username) {
        errors.value.username = "Gebruikersnaam is verplicht";
        valid = false;
      }
      if (!form.value.first_name) {
        errors.value.first_name = "Naam is verplicht";
        valid = false;
      }
      if (!form.value.last_name) {
        errors.value.last_name = "Achternaam is verplicht";
        valid = false;
      }
      if (!form.value.email) {
        errors.value.email = "E-mail is verplicht";
        valid = false;
      } else if (!validEmail(form.value.email)) {
        errors.value.email = "Ongeldig e-mailadres";
        valid = false;
      }
      if (!form.value.roles || form.value.roles.length === 0) {
        errors.value.roles = "Minimaal 1 rol is verplicht";
        valid = false;
      }
      return valid;
    };

    // Computed property that returns true if the form is valid.
    const isFormValid = computed(() => {
      return (
        form.value.username &&
        form.value.first_name &&
        form.value.last_name &&
        form.value.email &&
        validEmail(form.value.email) &&
        form.value.roles &&
        form.value.roles.length > 0
      );
    });

    onMounted(async () => {
      await fetchAllOwners();
      await fetchAllRoles();
      if (route.params.id) {
        isEditMode.value = true;
        await fetchUser(route.params.id);
      }
    });

    const fetchAllOwners = async () => {
      try {
        const response = await axios.get("/beheerapi/owners/list");
        allOwners.value = response.data;
      } catch (error) {
        console.error("Fout bij ophalen van alle eigenaren:", error);
      }
    };

    // Fetch all roles from the API.
    const fetchAllRoles = async () => {
      try {
        const response = await axios.get("/beheerapi/roles/adminList");
        allRoles.value = response.data;
      } catch (error) {
        console.error("Fout bij ophalen van alle rollen:", error);
      }
    };

    const fetchUser = async (id) => {
      try {
        const response = await axios.get(`/beheerapi/users/${id}`);
        const data = response.data;
        form.value = {
          ...form.value,
          ...data,
          roles: data.roles || [],
          company:
            data.company_id && data.company_id !== 0
              ? companies.find((company) => company.id === data.company_id)
              : companies[0],
          owner:
            data.owner_id && allOwners.value.length
              ? allOwners.value.find((owner) => owner.id === data.owner_id) ||
                null
              : null,
        };
      } catch (error) {
        console.error("Fout bij ophalen van gebruiker:", error);
      }
    };

    // Use the allRoles list for suggestions.
    const searchRoles = (event) => {
      const query = (event.query || "").toLowerCase();
      roleSuggestions.value = allRoles.value.filter((role) =>
        role.name.toLowerCase().includes(query)
      );
    };

    const searchCompanies = (event) => {
      const query = (event.query || "").toLowerCase();
      companySuggestions.value = companies.filter((company) =>
        company.executed_by.toLowerCase().includes(query)
      );
    };

    const searchOwners = (event) => {
      const query = (event.query || "").toLowerCase();
      ownerSuggestions.value = allOwners.value.filter(
        (owner) =>
          owner && owner.owner && owner.owner.toLowerCase().includes(query)
      );
    };

    const saveUser = async () => {
      // Validate form and display errors if any.
      if (!validateForm()) {
        return;
      }
      try {
        const payload = { ...form.value };

        // Convert roles to role_ids.
        if (Array.isArray(payload.roles) && payload.roles.length > 0) {
          payload.role_ids = payload.roles.map((role) => role.id);
        } else {
          payload.role_ids = [];
        }
        delete payload.roles;

        // Set owner_id.
        if (payload.owner && payload.owner.id) {
          payload.owner_id = payload.owner.id;
        } else {
          payload.owner_id = null;
        }
        delete payload.owner;

        // Set company_id.
        if (payload.company && payload.company.id !== undefined) {
          payload.company_id = payload.company.id;
        } else {
          payload.company_id = null;
        }
        delete payload.company;

        if (isEditMode.value) {
          await axios.put(`/beheerapi/users/${payload.id}`, payload);
        } else {
          await axios.post("/beheerapi/users", payload);
        }
        router.push("/beheer/users");
      } catch (error) {
        console.error("Fout bij opslaan van gebruiker:", error);
      }
    };

    const cancel = () => {
      router.push("/beheer/users");
    };

    return {
      form,
      isEditMode,
      saveUser,
      cancel,
      searchRoles,
      roleSuggestions,
      searchCompanies,
      companySuggestions,
      searchOwners,
      ownerSuggestions,
      companies,
      errors,
      isFormValid,
    };
  },
};
</script>
