<template>
  <div class="col-md-6 mx-auto">
    <h2 class="mb-4">
      {{ isEditMode ? "Rechten Bewerken" : "Nieuwe rol toevoegen" }}
    </h2>

    <form @submit.prevent="saveRole">
      <div class="form-group mb-3">
        <label for="name">Naam</label>
        <InputText
          id="name"
          v-model="form.name"
          class="form-control"
          required
        />
      </div>

      <!-- Permissions Checkboxes -->
      <div
        v-for="(label, key) in permissions"
        :key="key"
        class="flex align-items-center mb-2"
      >
        <Checkbox
          v-model="form.permissions[key]"
          :inputId="key"
          :binary="true"
        />
        <label :for="key" class="ml-2">{{ label }}</label>
      </div>

      <!-- Buttons -->
      <Button
        type="submit"
        :label="isEditMode ? 'Bijwerken' : 'Toevoegen'"
        class="p-button-success mt-2"
      ></Button>
      <Button
        label="Annuleren"
        class="p-button-secondary mt-2 ml-2"
        @click="cancel"
      ></Button>
    </form>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import axios from "axios";

export default {
  components: {
    InputText,
    Button,
    Checkbox,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const form = ref({
      id: null,
      name: "",
      permissions: {},
    });

    const permissions = ref({});
    const isEditMode = ref(false);

    onMounted(() => {
      fetchPermissions();
      if (route.params.id) {
        isEditMode.value = true;
        fetchRole(route.params.id);
      }
    });

    const fetchPermissions = async () => {
      try {
        const response = await axios.get("/beheerapi/roles/permissions");
        permissions.value = response.data;

        // Initialize all permissions as false if not in edit mode
        Object.keys(response.data).forEach((key) => {
          form.value.permissions[key] = false;
        });
      } catch (error) {
        console.error("Fout bij het laden van permissies:", error);
      }
    };

    const fetchRole = async (id) => {
      try {
        const response = await axios.get(`/beheerapi/roles/${id}`);
        form.value = response.data;
        console.log(response.data);
        form.value.permissions = JSON.parse(response.data.permissions);
      } catch (error) {
        console.error("Fout bij het laden van rolgegevens:", error);
      }
    };

    const saveRole = async () => {
      try {
        if (isEditMode.value) {
          await axios.put(`/beheerapi/roles/${form.value.id}`, form.value);
        } else {
          await axios.post("/beheerapi/roles", form.value);
        }
        router.push("/beheer/roles");
      } catch (error) {
        console.error("Fout bij het opslaan van de rol:", error);
      }
    };

    const cancel = () => {
      router.push("/beheer/roles");
    };

    return {
      form,
      permissions,
      isEditMode,
      saveRole,
      cancel,
    };
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
}
.flex {
  display: flex;
  align-items: center;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
</style>
