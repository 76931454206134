import { createStore } from 'vuex';
import { ScaleLine } from 'ol/control';
import { Circle } from 'ol/geom';
import { fromCircle } from 'ol/geom/Polygon';
const store = createStore({
    state: {
        activeTab: 'Legenda',
        map: {},
        interaction: undefined,
        activeTool: undefined,
        infoClick: undefined,
        clickEvent: undefined,
        infoClickState: false,
        newFeature: undefined,
        canCreate: false,
        scaleline: new ScaleLine({bar: true, minWidth: 150}),
        loading: false
    },
    mutations: {
        setMap(state, map) {
            state.map = map;
        },
        newInteraction (state, interaction) {
            state.interaction = interaction
        },
        activeTool (state, evt) {
            state.activeTool = evt
        },
        infoClick (state, interaction) {
            state.infoClick = interaction
        },
        infoClickState (state, evt) {
            state.infoClickState = evt
        },
        setActiveTab(state, tab) {
            state.activeTab = tab;
        },
        clickEvent (state, evt) {
            state.clickEvent = evt
        },
        setNewFeature (state, evt) {
            state.newFeature = evt
        },
        setLoading (state, evt) {
            state.loading = evt
        },
        setCanCreate(state, evt) {
            state.canCreate = evt
        }
    },
    actions: {
        toggleClickOff (state) {
            state.commit('infoClickState', false);
            // This does not disable the click in any way
            // @Todo fix this
            state.getters.getMap.un('singleclick', state.getters.infoClick);
            // state.getters.getMap.removeInteraction(state.getters.infoClick);
            // state.getters.getMap.unset('singleclick');
            // state.commit('infoClick', undefined);
        },
        toggleClickOn (state) {
            state.commit('infoClickState', true);
            state.commit('infoClick',
                state.getters.getMap.on('singleclick', evt => {
                    // Temporary fix to disable the click
                    if (state.getters.infoClickState) {
                        const circleGeom = new Circle(evt.coordinate, 10);
  
                        state.commit('setActiveTab', 'Info')
                        state.commit('clickEvent', {
                            "type": "Polygon",
                            "coordinates": fromCircle(circleGeom).getCoordinates()
                        });
                    }
                })
            );
        }
    },
    getters: {
        activeTab: state => state.activeTab,
        interaction: state => state.interaction,
        getMap: state => state.map,
        clickEvent: state => state.clickEvent,
        infoClick: state => state.infoClick,
        infoClickState: state => state.infoClickState,
        activeTool: state => state.activeTool,
        newFeature: state => state.newFeature,
        scaleline: state => state.scaleline,
        loading: state => state.loading,
        canCreate: state => state.canCreate
    }
});

export default store;