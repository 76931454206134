<template>
  <div class="col-md-10">
    <h3>Rollen</h3>
    <DataTable
      :value="roles"
      paginator
      sortMode="multiple"
      :lazy="true"
      :rows="pagination.per_page"
      :rowsPerPageOptions="[10, 20, 50, 100]"
      :totalRecords="pagination.total"
      @page="updatePagination"
      @sort="updatePagination"
      stripedRows
    >
      <Column field="name" header="Name" style="width: 25%"></Column>
      <Column key="link" field="id" header="Bewerken" :sortable="false">
        <template v-slot:body="slotProps">
          <Button
            icon="pi pi-pencil"
            severity="success"
            variant="text"
            rounded
            aria-label="Bewerken"
            @click="$router.push('/beheer/roles/' + slotProps.data.id)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import axios from "axios";
import Menu from "primevue/menu";
import { useRouter } from "vue-router";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Button from "primevue/button";

export default {
  name: "RolesOverviewComponent",
  components: {
    DataTable,
    Column,
    ColumnGroup,
    Row,
    Button,
  },
  props: {
    organisation: {},
  },
  data() {
    return {
      roles: [],
      pagination: {
        current_page: 0,
        last_page: 1,
        per_page: 10,
        total: 0,
        sort: undefined,
      },
      search: "",
    };
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    async getRoles() {
      const response = await axios.get("/beheerapi/roles/list", {
        params: {
          page: this.pagination.current_page + 1,
          per_page: this.pagination.per_page,
          search: this.search,
          sort: JSON.stringify(this.pagination.sort),
        },
      });

      console.log(response);

      this.roles = response.data.roles.data;
      this.pagination = {
        current_page: response.data.roles.current_page,
        last_page: response.data.roles.last_page,
        per_page: +response.data.roles.per_page,
        total: response.data.roles.total,
      };
      console.log(response);
    },
    updatePagination(event) {
      this.pagination.current_page = event.page;
      this.pagination.perPage = event.rows;
      // this.pagination.sort = event.page;

      this.getRoles();
    },
  },
};
</script>

<style>
</style>
