<template>
  <div class="btn-group b_wrap_mass_editing">
    <div class="btn-group">
      <!-- Kaart Link -->
      <Button
        class="p-button-success p-button-sm"
        icon="pi pi-globe"
        :tooltip="'Selectie overzetten naar de kaart'"
        tooltipOptions="{ position: 'top' }"
        @click="navigateTo('/kaart/onderhoud')"
      />

      <!-- Export Link -->
      <Button
        v-if="hasPermission(['admin', 'user', 'export'])"
        class="p-button-success p-button-sm"
        icon="pi pi-file-excel"
        :tooltip="'Exporteren'"
        tooltipOptions="{ position: 'top' }"
        @click="navigateTo('/onderhoud/export')"
      />

      <!-- Print Link -->
      <Button
        class="p-button-success p-button-sm"
        icon="pi pi-print"
        :tooltip="'Print maken'"
        tooltipOptions="{ position: 'top' }"
        @click="navigateTo('/onderhoud/print')"
      />

      <!-- Update Planning Button -->
      <Button
        v-if="hasPermission(['super', 'admin', 'update_planning'])"
        class="p-button-success p-button-sm"
        icon="pi pi-calendar"
        :tooltip="'Planning bijwerken'"
        tooltipOptions="{ position: 'top' }"
        @click="showUpdatePlanning"
      />

      <!-- Maintenance Reminder Button -->
      <Button
        v-if="hasPermission(['super', 'admin', 'remind_maintenance'])"
        class="p-button-success p-button-sm"
        icon="pi pi-envelope"
        :tooltip="'Herrinering onderhoud verzenden'"
        tooltipOptions="{ position: 'top' }"
        @click="remindMaintenance"
      />
    </div>

    <div
      v-if="
        hasPermission([
          'super',
          'admin',
          'user',
          'maintenance_executed',
          'maintenance_planned',
          'maintenance_postponed',
          'maintenance_cancelled',
          'maintenance_execution_reported',
          'maintenance_vision',
        ])
      "
    >
      <Select
        :options="organisation.maintenance_statuses"
        optionLabel="maintenance_status_nl"
        @change="(event) => updateStatus(event)"
        :placeholder="`Status wijzigen (${selectedAmount} geselecteerd)`"
        class=""
      >
        <template #value="slotProps">
          <div v-if="slotProps.value" class="flex items-center">
            <div>{{ slotProps.value.maintenance_status_nl }}</div>
          </div>
        </template>
        <template #option="slotProps">
          <div
            class="flex items-center"
            v-if="
              hasPermission([
                'admin',
                'user',
                'maintenance_' + slotProps.option.maintenance_status,
              ])
            "
          >
            <div>{{ slotProps.option.maintenance_status_nl }}</div>
          </div>
        </template>
      </Select>
    </div>

    <br />
    <ToggleSwitch
      inputId="history"
      :model-value="modelValue"
      @update:model-value="historyChange"
    />
    <label for="history"> Geschiedenis </label>
  </div>

  <br />

  <Dialog
    v-model:visible="remind_maintenance"
    :draggable="false"
    modal
    header="Herinnering onderhoud"
    :style="{ width: '25vw', height: '50rem' }"
  >
    <div class="dialog-container">
      <div class="dialog-content">
        <ul>
          <p>Er wordt een email verstuurd naar:</p>
          <li v-bind:key="email" v-for="email in emails">{{ email }}</li>
        </ul>
      </div>
      <div class="dialog-footer">
        <Button
          class="btn btn-primary updating_planning"
          @click="sendRemindMaintenance"
        >
          Herinnering verzenden
        </Button>
      </div>
    </div>
  </Dialog>

  <Dialog
    v-model:visible="remind_maintenance_result"
    :draggable="false"
    modal
    header="Email Status"
    :style="{ width: '25vw' }"
  >
    <div class="dialog-content">
      <h4>Er zijn succesvol {{ count }} emails verstuurd.</h4>
      <div v-if="emails.length > 0">
        <p>Het is mislukt om een email te sturen naar:</p>
        <ul>
          <li v-for="email in emails" :key="email">{{ email }}</li>
        </ul>
      </div>
    </div>
  </Dialog>

  <Dialog
    v-model:visible="showPlanning"
    :draggable="false"
    modal
    header="Bevestig planning bijwerken"
    :style="{ width: '30vw' }"
  >
    <div class="dialog-content">
      <ul>
        <li>Uitgevoerd: {{ maintenancePlanning.executed }}</li>
        <li>Uitgesteld: {{ maintenancePlanning.postponed }}</li>
        <li>Geannuleerd: {{ maintenancePlanning.cancelled }}</li>
      </ul>
    </div>
    <div class="dialog-footer">
      <button class="btn btn-primary updating_planning" @click="updatePlanning">
        Planning bijwerken
      </button>
    </div>
  </Dialog>
</template>
<script>
import { inject } from "vue";
import ToggleSwitch from "primevue/toggleswitch";
import axios from "axios";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import { ref } from "vue";
import Select from "primevue/select";

export default {
  components: {
    ToggleSwitch,
    Dialog,
    Button,
    Select,
  },
  emits: ["update-table", "update:modelValue"],
  props: {
    selectedAmount: {
      type: Number,
      default: 0,
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
    organisation: {
      type: Object,
      required: true,
    },
  },
  setup(_, { emit }) {
    const remind_maintenance = ref(false);
    const remind_maintenance_result = ref(false);
    const showPlanning = ref(false);
    const emails = ref([]);
    const count = ref(0);
    const maintenancePlanning = ref({});

    const selectAll = () => {
      emit("update-table");
    };

    const navigateTo = (path) => {
      window.open(path, "_blank");
    };

    const clearSelection = () => {
      emit("update-table");
    };

    const changeStatus = (status) => {
      // Placeholder for updating status logic
      emit("update-table");
    };

    const updateTable = () => {
      console.log("swithch");
      emit("update-table");
    };

    const historyChange = (value) => {
      console.log(value);
      emit("update:modelValue", value);
      emit("update-table");
    };

    const checkAuthorization = inject("checkAuthorization");

    const hasPermission = (perms) => {
      return checkAuthorization?.(perms);
    };

    const remindMaintenance = async () => {
      remind_maintenance.value = true;
      count.value = 0;
      emails.value = [];

      const response = await axios.get("/onderhoud/reminder_confirm");
      emails.value = response.data.emails;
    };

    const sendRemindMaintenance = async () => {
      remind_maintenance.value = false;

      const response = await axios.get("/onderhoud/reminder");
      emails.value = response.data.problems;
      count.value = response.data.count;
      remind_maintenance_result.value = true;
    };

    const showUpdatePlanning = async () => {
      showPlanning.value = true;
      maintenancePlanning.value = {};

      const response = await axios.get("/onderhoud/update/planning");
      maintenancePlanning.value = response.data;
    };
    const updatePlanning = async () => {
      await axios.post("/onderhoud/update/planning");
      showPlanning.value = false;
    };

    const updateStatus = async (event) => {
      emit("update-status", event.value.maintenance_status);
    };

    return {
      selectAll,
      clearSelection,
      changeStatus,
      navigateTo,
      hasPermission,
      updateTable,
      historyChange,
      remindMaintenance,
      sendRemindMaintenance,
      updateStatus,
      updatePlanning,
      showUpdatePlanning,
      remind_maintenance,
      emails,
      remind_maintenance_result,
      count,
      showPlanning,
      maintenancePlanning,
    };
  },
};
</script>
  
<style scoped>
.dialog-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%; /* Set this to ensure proper alignment */
}

.dialog-content {
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dialog-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dialog-content li {
  margin: 5px 0;
}

.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.dialog-footer .btn {
  margin: 0 10px; /* Optional: Add space around buttons */
}
</style>
  