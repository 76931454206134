<template>
  <div>
    <!-- FileUpload Section -->
    <ProgressBar
      v-if="totalSizePercent"
      :value="totalSizePercent"
      :showValue="false"
    >
      <span class="whitespace-nowrap">{{ totalSize }}B / 1Mb</span>
    </ProgressBar>

    <FileUpload
      name="picture[]"
      :url="'/elementen/store/picture/' + this.element.id"
      @upload="onUpload"
      :multiple="false"
      class="float-end"
      accept="image/*"
      @select="onImageSelect"
    >
      <template #header="{ chooseCallback }">
        <div
          style="justify-content: end; width: 100%; display: flex;"
            class="row d-flex justify-content-end w-100"
          >
          <Button
            @click="chooseCallback()"
            label="Afbeelding toevoegen"
            class="p-button-success col-md-4"
            icon="pi pi-plus"
          />

          <Button
            icon="pi pi-pencil"
            severity="success"
            rounded
            outlined
            @click="openEditModal()"
          />

        </div>
      </template>
      <template
        #content="{
          files,
          uploadedFiles,
          removeUploadedFileCallback,
          removeFileCallback,
        }"
      >
        <Message
          v-for="message of messages"
          :key="message"
          :class="!files.length && !uploadedFiles.length ? '' : 'mb-8'"
          severity="error"
        >
          {{ message }}
        </Message>
        <div v-if="previewImage" class="preview-section">
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 20px;
            "
          >
          <img
              :src="previewImage"
              alt="Preview"
              style="
                max-width: 100%;
                max-height: 300px;
                object-fit: contain;
                padding: 10px;
              "
            />
            <br />

            <div
              style="justify-content: center; width: 100%; display: flex;"
              class="row mt-2 d-flex justify-content-center w-100"
            >
              <InputText v-model="title" placeholder="Titel" class="col-md-5"></InputText>
              <DatePicker
                v-model="recorded_at"
                dateFormat="yy/mm/dd"
                placeholder="Datum"
                required
                class="col-md-5"
              />
            </div>
            <br>
            <div
              style="justify-content: space-between; width: 100%; display: flex;"
              class="row mt-2"
            >
              <Button
                  label="Annuleren"
                  class="p-button-secondary col-md-4"
                  icon="pi pi-times"
                  @click="cancelPreview(file, removeFileCallback)"
                />

                <Button
                  label="Opslaan"
                  class="p-button-success col-md-4"
                  icon="pi pi-check"
                  @click="uploadImage(removeFileCallback)"
                />
              </div>
          </div>
        </div>
      </template>
      <template #empty>
        <!-- Galleria Section -->
        <div v-if="element.pictures && !previewImage">
          <Galleria
            :value="element.pictures"
            containerStyle="max-height: 400px; width: 100%; overflow: hidden; border: none;"
            :showThumbnails="false"
            :showIndicators="true"
            :changeItemOnIndicatorHover="true"
            v-model:activeIndex="currentIndex"
          >
            <template #item="slotProps">
              <div
                class="row"
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
              <!-- :src="'https://boomapp.nl' + slotProps.item.source" -->
              <img
                  :src="slotProps.item.source"
                  :alt="slotProps.item.name"
                  style="
                    max-width: 100%;
                    max-height: 300px;
                    object-fit: contain;
                    padding: 10px;
                  "
                  @click="openImageDialog(slotProps.item)"
                />

                <div
                  class="row mt-2 w-100 text-center"
                >
                  <p style="margin: 0">{{ slotProps.item.date }}</p>
                  <p style="margin: 0">{{ slotProps.item.name }}</p>
                </div>
              </div>
            </template>
          </Galleria>
        </div>
      </template>
    </FileUpload>

    <Dialog
      :draggable="false"
      v-model:visible="modalOpen"
      modal
      header="Afbeelding Bewerken"
      :style="{ width: '35vw', height: '50rem' }"
    >
      <img
        :src="currentImage.source"
        alt="Edit Preview"
        style="
          max-width: 100%;
          max-height: 300px;
          object-fit: contain;
          border: 1px solid #ccc;
          padding: 10px;
          margin-bottom: 10px;
        "
      />
      <div class="form-group">
        <label for="title">Titel</label>
        <InputText v-model="currentImage.name" placeholder="Titel" id="title" />
      </div>
      <div class="form-group">
        <label for="date">Datum</label>
        <DatePicker v-model="currentImage.date" id="date" />
      </div>
      <div class="form-actions">
        <Button class="btn btn-warning" @click="rotateImage(currentImage)">
          Roteren
        </Button>
        <button
          class="btn btn-danger"
          @click="confirmRemoveImage(currentImage)"
        >
          Verwijderen
        </button>
        <button class="btn btn-success" @click="saveImageDetails">
          Opslaan
        </button>
      </div>
    </Dialog>
    <Dialog
      v-model:visible="largeImageDialogOpen"
      :draggable="false"
      modal
      header="Afbeelding bekijken"
      :style="{ width: '80vw', height: '80vh' }"
    >
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        "
      >
        <img
          :src="largeImageSrc"
          alt="Grote afbeelding"
          style="max-width: 100%; max-height: 100%; object-fit: contain"
        />
      </div>
    </Dialog>
  </div>
</template>

<script>
import { ref } from "vue";
import Galleria from "primevue/galleria";
import FileUpload from "primevue/fileupload";
import Button from "primevue/button";
import DatePicker from "primevue/datepicker";
import InputText from "primevue/inputtext";
import axios from "axios";
import Dialog from "primevue/dialog";
import Message from "primevue/message";
import ConfirmDialog from "primevue/confirmdialog";
import { useConfirm } from "primevue/useconfirm";
import Toast from 'primevue/toast';
import { useToast } from "primevue/usetoast";

export default {
  components: {
    Galleria,
    FileUpload,
    Button,
    DatePicker,
    InputText,
    Dialog,
    Message,
    ConfirmDialog,
    Toast,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const toast = useToast();
    const confirm = useConfirm();
    return { confirm, toast };
  },
  data() {
    return {
      modalOpen: false,
      currentIndex: 0,
      largeImageDialogOpen: false,
      largeImageSrc: null,
      currentImage: null,
      previewImage: null,
      recorded_at: new Date().toISOString().split("T")[0],
      title: ''
    };
  },
  methods: {
    onUpload() {    },
    onImageSelect(event) {
      const file = event.files[0];
      if (file) {
        this.selectedFile = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    uploadImage(removeUploadedFileCallback) {
      const formData = new FormData();
      formData.append("file", this.selectedFile);
      if (this.recorded_at) {
        const formattedDate = this.formatDate(this.recorded_at);
        formData.append("newDate", formattedDate);
      }
      formData.append("name", this.title);

      axios
        .post("/elementen/store/picture/" + this.element.id, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          this.toast.add({ severity: 'success', summary: 'Afbeelding is succesvol opgeslagen.', detail: '', life: 3000 });
          console.log(response);
          this.element.pictures.push(response.data.success);
          removeUploadedFileCallback();
          this.previewImage = null;
          this.selectedFile = null;
          this.$emit("refresh");
        })
        .catch((error) => {
          console.error("Upload failed:", error);
          this.toast.add({ severity: 'error', summary: 'Er was een probleem bij het uploaden van de afbeelding.', detail: '', life: 3000 });
        });
    },
    cancelPreview(file, removeFileCallback) {
      removeFileCallback();
      this.previewImage = null;
      this.selectedFile = null;
    },
    openEditModal() {
      this.currentImage = this.element.pictures[this.currentIndex];
      this.modalOpen = true;
      console.log(this.modalOpen);
    },
    closeEditModal() {
      this.modalOpen = false;
      this.currentImage = null;
    },
    openImageDialog(image) {
      this.largeImageSrc = image.source;
      this.largeImageDialogOpen = true;
    },
    rotateImage(image) {
      axios
        .post(
          `/elementen/rotate/image/${image.id}`,
          {},
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((response) => {
          this.element.pictures.push(response.data.success);
        })
        .catch((error) => {
          console.error("Upload failed:", error);
        });
    },
    confirmRemoveImage(image) {
      this.confirm.require({
        message: "Weet u zeker dat u deze afbeelding wilt verwijderen?",
        header: "Bevestig Verwijdering",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Verwijderen",
        rejectLabel: "Annuleren",
        accept: async () => {
          await this.removeImage(image);
        },
      });
    },
    async removeImage(image) {
      try {
        await axios.delete(`/elementen/delete_picture/${image.id}`);
        this.element.pictures = this.element.pictures.filter(
          (item) => item.id !== image.id
        );
        this.modalOpen = false;
      } catch (error) {
        console.error("Fout bij verwijderen:", error);
      }
    },
    saveImageDetails() {
      axios
        .post(`/elementen/update_picture`, {
          pictureId: this.currentImage.id,
          name: this.currentImage.name,
          newDate: this.currentImage.date,
        })
        .then(() => {
          const index = this.element.pictures.findIndex(
            (item) => item.id === this.currentImage.id
          );
          this.modalOpen = false;
        })
        .catch((error) => {
          console.error("Fout bij opslaan van details:", error);
        });
    },
    formatDate(date) {
      const d = new Date(date);
      const day = d.getDate().toString().padStart(2, "0");
      const month = (d.getMonth() + 1).toString().padStart(2, "0");
      const year = d.getFullYear();
      return `${year}-${month}-${day}`; // Formaat: DD-MM-YYYY
    }
  },
};
</script>

<style scoped>
.edit-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
.form-group {
  margin-bottom: 15px;
}
.form-actions {
  display: flex;
  justify-content: space-between;
}

.uploadButton {
  border: 1px;
  display: flex;
  justify-content: end;
  float: inline-end;
}
div.p-fileupload-header {
  border: 1px;
  display: flex;
  justify-content: end !important;
}

.p-fileupload-advanced {
  border: none;
}
</style>
