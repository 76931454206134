/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.VueMultiselect = require('vue-multiselect');
import { createApp } from 'vue';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import store from './store';
import Vue from 'vue';
window.Vue = require('vue');

import 'es6-promise/auto'
import MapComponent from './components/map/MapComponent.vue';
import ShowComponent from './components/element/ShowComponent.vue';
import GeneralComponent from './components/element/GeneralComponent.vue';
import Maintenance from './components/maintenance_table/Maintenance.vue';
import Element from './components/element_table/Element.vue';
import PrimeVue from 'primevue/config';

import 'primeicons/primeicons.css';
import Aura from '@primevue/themes/aura';
import AdminComponent from './components/admin/AdminComponent.vue';
import GeomCru from './components/GeomCru.vue';
import UserOverviewComponent from './components/admin/users/UserOverviewComponent.vue';
import UserComponent from './components/admin/users/UserComponent.vue';
import RolesComponent from './components/admin/roles/RolesComponent.vue';
import RolesOverviewComponent from './components/admin/roles/RolesOverviewComponent.vue';
import DashboardComponent from './components/admin/DashboardComponent.vue';
import { createRouter, createWebHistory } from 'vue-router';
import { ConfirmationService } from 'primevue';
import ToastService from 'primevue/toastservice';

const app = createApp({
  components: {
    MapComponent,
  }
});
app.use(store);
app.mount('#map');

const geomcru = createApp({
  components: {
    GeomCru,
  }
});
geomcru.use(store);
geomcru.mount('#geom_cru');

// const formmap = createApp({
//   components: {
//     MapComponent,
// }
// });
// formmap.use(store);
// formmap.mount('#form_map');

const app3 = createApp({
  components: {
    Maintenance,
  }
});
app3.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      darkModeSelector: false || 'none',
    }
  }
})
app3.mount('#maintenance');

const app4 = createApp({
  components: {
    Element,
  }
});

app4.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      darkModeSelector: false || 'none',
    }
  }
})
app4.mount('#element');

const app5 = createApp({
  components: {
    ShowComponent,
  }
});
app5.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      darkModeSelector: false || 'none',
    }
  }
})
app5.use(ConfirmationService)
app5.use(ToastService)
app5.mount('#show')

const routes = [
  { path: '/beheer', component: DashboardComponent },
  { path: '/beheer/users', component: UserOverviewComponent },
  { path: '/beheer/users/new', component: UserComponent },
  { path: '/beheer/users/:id', component: UserComponent },
  { path: '/beheer/roles', component: RolesOverviewComponent },
  { path: '/beheer/roles/new', component: RolesComponent },
  { path: '/beheer/roles/:id', component: RolesComponent },
  // { path: '/settings', component: Settings },
];

const adminRouter = createRouter({
  history: createWebHistory(),
  routes,
});

const admin = createApp({
  components: {
    AdminComponent,
  }
});
admin.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      darkModeSelector: false || 'none',
    }
  }
})
admin.use(adminRouter);
admin.mount('#admin')
