<template>
  <div v-if="data">
    <DataTable
      :value="data"
      paginator
      sortMode="multiple"
      selectionMode="multiple"
      v-model:selection="selectedRows"
      :lazy="true"
      :rows="pagination.per_page"
      :rowsPerPageOptions="[10, 20, 50, 100]"
      :totalRecords="pagination.total"
      @page="updatePagination"
      @sort="onSort"
      @rowSelect="onRowSelect"
      @rowUnselect="onRowUnselect"
      stripedRows
      tableStyle="min-width: 50rem"
      class="table-maintenance"
    >
      <Column key="link" field="element.id" header="Kaart" :sortable="false">
        <template v-slot:body="slotProps">
          <span v-if="slotProps.data.element">
            <a
              :href="'/kaart/elementen/' + slotProps.data.element.id"
              target="_blank"
            >
              <span class="glyphicon glyphicon-globe"></span>
            </a>
          </span>
        </template>
      </Column>

      <Column
        key="element_lsn"
        field="element.lsn"
        header="LSN"
        :sortable="true"
      >
        <template #body="slotProps">
          <span v-if="slotProps.data.element">
            <a :href="'/elementen/' + slotProps.data.element.id">
              {{ slotProps.data.element.lsn }}
            </a>
          </span>
          <span v-else>verwijderd</span>
        </template>
      </Column>

      <Column
        key="maintenance_status"
        field="status.maintenance_status"
        header="Status"
        :sortable="false"
      >
        <template #body="slotProps">
          <div
            :class="'status ' + slotProps.data.status.maintenance_status"
            :data-status-id="slotProps.data.maintenance_id"
          ></div>
        </template>
      </Column>

      <Column key="notes" field="notes" header="Notities" :sortable="false">
        <template #body="slotProps">
          <span
            v-if="slotProps.data.notes.length > 0"
            class="click-open-notes"
            @click="showNotes(slotProps.data)"
          >
            {{ slotProps.data.notes.length }}
          </span>
          <span
            v-else
            class="click-open-notes"
            @click="showNotes(slotProps.data)"
          >
            +
          </span>
        </template>
      </Column>

      <Column
        key="owners"
        field="element.owners"
        header="Naam deelnemers"
        :sortable="false"
        v-if="organisation.custom_view !== 'sbb'"
      >
        <template #body="slotProps">
          <span v-if="slotProps.data.element">
            {{
              slotProps.data.element.owners
                .map((owner, index) =>
                  index ? `, ${owner.owner}` : owner.owner
                )
                .join("")
            }}
          </span>
          <span v-else>verwijderd</span>
        </template>
      </Column>

      <Column
        key="subsidy_owner"
        field="element.subsidy.deeln_naam"
        header="Eigenaar"
        :sortable="false"
        v-if="organisation.custom_view == 'sbb'"
      >
        <template #body="slotProps">
          <span v-if="slotProps.data.element">
            {{
              slotProps.data.element.owners
                .map((owner, index) =>
                  index ? `, ${owner.owner}` : owner.owner
                )
                .join("")
            }}
          </span>
          <span v-else>verwijderd</span>
        </template>
      </Column>

      <Column
        key="executed_by"
        field="executed_by.executed_by"
        header="Uitvoerder"
        :sortable="false"
      />

      <Column
        key="location"
        field="element.location.location"
        header="Locatie"
        :sortable="false"
      />

      <Column
        key="element_type"
        field="element.elementtype.element_type_nl"
        header="Element"
        :sortable="false"
      />

      <Column
        key="objective"
        field="element.objective.objective_nl"
        header="Streefbeeld"
        :sortable="false"
      />

      <Column
        key="shape"
        field="element.shape.shape_nl"
        header="Vorm"
        :sortable="true"
      />

      <Column
        key="maintenance_type"
        field="maintenancetype.maintenance_type_nl"
        header="Maatregel"
        :sortable="false"
      />

      <Column
        key="period_from"
        field="period_from"
        header="Periode vanaf"
        :sortable="true"
      >
        <template #body="slotProps">
          {{ new Date(slotProps.data.period_from).toLocaleDateString("nl-NL") }}
        </template>
      </Column>

      <Column
        key="period_to"
        field="period_to"
        header="Periode tot en met"
        :sortable="true"
      >
        <template #body="slotProps">
          {{ new Date(slotProps.data.period_to).toLocaleDateString("nl-NL") }}
        </template>
      </Column>

      <Column
        key="cycle"
        field="cycle.cycle_nl"
        header="Cyclus"
        :sortable="false"
      />

      <Column
        key="phasing"
        field="phasing.phasing"
        header="Fasering"
        :sortable="true"
      />

      <Column
        key="diameter"
        field="element.diameter.diameter"
        header="Diameter"
        :sortable="false"
      />

      <Column key="unit" field="unit.unit" header="Eenheid" :sortable="false" />

      <Column
        key="calculation_value"
        field="calculation_value"
        header="Rekenwaarde"
        :sortable="true"
      />

      <Column key="tool" field="tool" header="Gereedschap" :sortable="false">
        <template #body="slotProps">
          <span v-if="slotProps.data.tool[0]">
            {{ slotProps.data.tool[0]["tool_nl"] }}
          </span>
        </template>
      </Column>

      <Column
        key="waste"
        field="waste"
        header="Vrijgekomen materiaal"
        :sortable="false"
      >
        <template #body="slotProps">
          <span v-if="slotProps.data.waste[0]">
            {{ slotProps.data.waste[0]["waste_nl"] }}
          </span>
        </template>
      </Column>

      <Column
        key="processing"
        field="processing"
        header="Verwerking"
        :sortable="true"
      >
        <template #body="slotProps">
          <span v-if="slotProps.data.processing[0]">
            {{ slotProps.data.processing[0]["processing_nl"] }}
          </span>
        </template>
      </Column>
    </DataTable>
  </div>

  <Dialog
    v-model:visible="noteModal"
    :draggable="false"
    modal
    header="Notities"
    :style="{ width: '35vw', height: '50rem' }"
  >
    <NotesContent
      v-if="selectedMaintenance"
      :notes="selectedMaintenance.notes"
      :elementId="selectedMaintenance.element_id"
      :maintenanceId="selectedMaintenance.id"
      permissionForAdd="add_maintenance_notes"
      @note-added="noteAdded"
      @note-updated="noteUpdated"
      @note-deleted="noteDeleted"
    />
  </Dialog>
</template>

<script>
import { ref, inject, toRaw } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import NotesContent from "../NotesContent.vue";

export default {
  name: "MaintenanceTable",
  components: {
    DataTable,
    Column,
    ColumnGroup,
    Row,
    Dialog,
    InputText,
    Button,
    NotesContent,
  },
  props: {
    data: Array,
    pagination: Object,
    organisation: Object,
  },
  data() {
    return {
      selectedRows: [],
      noteModal: false,
      selectedMaintenance: null,
      sort: null,
    };
  },
  methods: {
    updatePagination(event) {
      console.log(event);
      this.$emit("pagination-changed", {
        page: event.page + 1,
        perPage: event.rows,
        sort: this.sort,
      });
    },
    showNotes(maintenance) {
      this.selectedMaintenance = maintenance;
      this.noteModal = true;
    },
    async noteAdded(newNote) {
      if (this.selectedMaintenance) {
        this.selectedMaintenance.notes.unshift(newNote);
      }
    },
    noteUpdated(updatedNote) {
      if (this.selectedMaintenance) {
        const index = this.selectedMaintenance.notes.findIndex(
          (n) => n.id === updatedNote.id
        );
        if (index !== -1) {
          this.selectedMaintenance.notes.splice(index, 1, updatedNote);
        }
      }
    },
    noteDeleted(deletedNote) {
      if (this.selectedMaintenance) {
        const index = this.selectedMaintenance.notes.findIndex(
          (n) => n.id === deletedNote.id
        );
        if (index !== -1) {
          this.selectedMaintenance.notes.splice(index, 1);
        }
      }
    },
    formatDate(date) {
      const options = { day: "2-digit", month: "short", year: "numeric" };
      return new Date(date)
        .toLocaleDateString("en-US", options)
        .replace(",", "");
    },
    hasPermission(perms) {
      const checkAuthorization = inject("checkAuthorization");
      return checkAuthorization?.(perms) || false;
    },
    onSort(event) {
      this.sort = event.multiSortMeta;
      this.$emit("pagination-changed", { sort: this.sort });
    },
    clearSelection() {
      this.selectedRows = [];
    },
    selectAll() {},
    onRowSelect(event) {
      const rows = this.selectedRows.map((row) => toRaw(row)) ?? [];
      this.$emit("rows-changed", { rows });
    },
    onRowUnselect(event) {
      const rows = this.selectedRows.map((row) => toRaw(row)) ?? [];
      this.$emit("rows-changed", { rows });
    },
  },
};
</script>

<style scoped>
.table-maintenance {
  width: 100%;
}

.table-maintenance div.status {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 12px;
  padding-top: 1px;
  text-align: center;
  line-height: 1;
  background-color: #ccc;
}
.table-maintenance div.status.planned {
  background-color: #ffd966;
}
.table-maintenance div.status.executed {
  background-color: #93c47d;
}
.table-maintenance div.status.postponed {
  background-color: #ffa75b;
}
.table-maintenance div.status.cancelled {
  background-color: #e06666;
}
.table-maintenance div.status.execution_reported {
  background-color: #e1bee7;
}
.table-maintenance div.status.vision {
  background-color: #6d8dff;
}

.click-open-notes {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #bbb;
}
</style>
