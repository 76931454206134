<template>
  <div class="col-md-12">
    <h4>
      Deze functionaliteit is verouderd en daarom uitgeschakeld, een nieuwe
      functionaliteit is in ontwikkeling.
    </h4>

    <p>Het is echter nog wel mogelijk om de oude formulieren te downloaden.</p>

    <ul v-for="form in organisation.forms" :key="form.id">
      <li v-for="(file, key) in files[form.route]" :key="file.id">
        <a :href="file" download target="_blank">
          <span class="glyphicon glyphicon-download"></span> {{ key }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, computed, inject } from "vue";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import AutoComplete from "primevue/autocomplete";
import InputNumber from "primevue/inputnumber";
import axios from "axios";

export default {
  name: "Forms",
  components: {
    AutoComplete,
    Dropdown,
    InputText,
    InputNumber,
  },
  data: () => ({}),
  props: {
    element: Object,
    element_data: Object,
    organisation: Object,
    user: Object,
    files: Object,
    filled_forms: Object,
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
/* Scoped styles specific to this component */
</style>
