<template>
  <div class="row">
    <div class="col-md-12">
      <div
        class="pull-right"
        v-if="
          hasPermission(['super', 'admin', 'manage_element']) ||
          (hasPermission(['super', 'admin', 'user', 'edit_element_general']) &&
            organisation.id === 4 &&
            elementHasOwner())
        "
      >
        <Button
          icon="pi pi-pencil"
          variant="text"
          severity="success"
          @click="toggleEdit"
        />
      </div>

      <div v-if="!editing">
        <div class="row">
          <div class="col-md-6">
            <b>Afdeling</b>
            <p>{{ element.district?.district }}</p>
          </div>
          <div class="col-md-6">
            <b>Locatie</b>
            <p>{{ element.location?.location }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b>Naam deelnemer</b>
            <p>{{ element.owners.map((item) => item.owner).join(", ") }}</p>
          </div>
          <div class="col-md-6">
            <b>Element</b>
            <p>{{ element.elementtype.element_type_nl }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b>Vorm</b>
            <p>{{ element.shape?.shape_nl }}</p>
          </div>
          <div class="col-md-6">
            <b>Streefbeeld</b>
            <p>{{ element.objective.objective_nl }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6" v-if="element.length">
            <b>Lengte</b>
            <p>{{ element.length }} m</p>
          </div>
          <div class="col-md-6" v-if="element.area">
            <b>Oppervlakte</b>
            <p>{{ element.area }} m²</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b>Diameter</b>
            <p>{{ element.diameter.diameter }}</p>
          </div>
          <div class="col-md-6">
            <b>Aantal</b>
            <p>{{ element.number_in_row }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b>Bedekkingsgraad</b>
            <p v-for="(field, index) in fields" :key="index">
              <template v-if="field.value === element.cover">
                {{ field.cover }}
              </template>
            </p>
          </div>
          <div class="col-md-6">
            <b>Project</b>
            <p>{{ element.project?.project }}</p>
          </div>
        </div>
      </div>

      <div v-if="editing">
        <div class="row">
          <div class="col-md-6">
            <b>Afdeling</b>
            <br />
            <AutoComplete
              v-model="element.district"
              dropdown
              optionLabel="district"
              :suggestions="suggestions['districtsArr']"
              @complete="search($event, 'districtsArr')"
            />
          </div>
          <div class="col-md-6">
            <b>Locatie</b>
            <br />
            <AutoComplete
              v-model="element.location"
              dropdown
              optionLabel="location"
              :suggestions="suggestions['locationsArr']"
              @complete="search($event, 'locationsArr')"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b>Naam deelnemer</b>
            <br />
            <AutoComplete
              v-model="element.owners"
              optionLabel="owner"
              multiple
              dropdown
              :suggestions="suggestions['ownersArr']"
              @complete="search($event, 'ownersArr')"
            />
          </div>
          <div class="col-md-6">
            <b>Element</b>
            <br />
            <AutoComplete
              v-model="element.elementtype"
              dropdown
              optionLabel="element_type_nl"
              :suggestions="suggestions['element_typesArr']"
              @complete="search($event, 'element_typesArr')"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b>Vorm</b>
            <br />
            <AutoComplete
              v-model="element.shape"
              dropdown
              optionLabel="shape_nl"
              :suggestions="suggestions['shapesArr']"
              @complete="search($event, 'shapesArr')"
            />
          </div>
          <div class="col-md-6">
            <b>Streefbeeld</b>
            <br />
            <AutoComplete
              v-model="element.objective"
              dropdown
              optionLabel="objective_nl"
              :suggestions="suggestions['objectivesArr']"
              @complete="search($event, 'objectivesArr')"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b>Diameter</b>
            <br />
            <AutoComplete
              v-model="element.diameter"
              dropdown
              optionLabel="diameter"
              :suggestions="suggestions['diametersArr']"
              @complete="search($event, 'diametersArr')"
            />
          </div>
          <div class="col-md-6">
            <b>Aantal</b>
            <InputNumber
              v-model="element.number_in_row"
              inputId="integeronly"
              fluid
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b>Bedekkingsgraad</b>
            <br />
            <Select
              v-model="element.cover"
              dropdown
              optionLabel="cover"
              optionValue="value"
              :options="fields"
            />
          </div>
          <div class="col-md-6">
            <b>Project</b>
            <br />
            <AutoComplete
              v-model="element.project"
              dropdown
              optionsLabel="project"
              :suggestions="suggestions['projectsArr']"
              @complete="search($event, 'projectsArr')"
            />
          </div>
        </div>
      </div>

      <div v-if="editing" class="pull-right">
        <button class="btn btn-success" @click="saveChanges">Opslaan</button>
        <button class="btn btn-danger" @click="toggleEdit">Annuleren</button>
      </div>
    </div>
  </div>

  <div v-if="hasPermission(['admin', 'user', 'add_element_notes'])">
    <Accordion>
      <AccordionTab header="Notities">
        <NotesContent
          :notes="element.notes"
          :parentId="element.id"
          :elementId="element.id"
          permissionForAdd="add_element_notes"
          @note-added="noteAdded"
        />
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script>
import { ref, inject, toRaw } from "vue";
import axios from "axios";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import AutoComplete from "primevue/autocomplete";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import Select from "primevue/select";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import NotesContent from "../NotesContent.vue";

export default {
  name: "General",
  components: {
    Button,
    AutoComplete,
    Dropdown,
    InputText,
    InputNumber,
    Select,
    Accordion,
    AccordionTab,
    NotesContent,
  },
  props: {
    element: Object,
    element_data: Object,
    organisation: Object,
    user: Object,
  },
  data() {
    return {
      editing: false,
      suggestions: {},
      fields: [
        { cover: "N.v.t.", value: null },
        { cover: "0-10%", value: 0 },
        { cover: "10-20%", value: 10 },
        { cover: "20-30%", value: 20 },
        { cover: "30-40%", value: 30 },
        { cover: "40-50%", value: 40 },
        { cover: "50-60%", value: 50 },
        { cover: "60-70%", value: 60 },
        { cover: "70-80%", value: 70 },
        { cover: "80-90%", value: 80 },
        { cover: "90-100%", value: 90 },
        { cover: "100%", value: 100 },
      ],
      inputNoteText: "",
    };
  },
  mounted() {
    this.setup();
  },
  methods: {
    setup() {
      if (!this.element.shape) this.element.shape = {};
      if (!this.element.location) this.element.location = {};
      if (!this.element.profit) this.element.profit = {};
    },
    toggleEdit() {
      this.editing = !this.editing;
    },
    async saveChanges() {
      // Toggle editing off
      this.toggleEdit();
      const body = {
        id: this.element.id,
        district_id: this.element.district?.id || null,
        location_id: this.element.location?.id,
        owners: toRaw(this.element.owners),
        element_type_id: this.element.elementtype.id,
        shape_id: this.element.shape?.id || null,
        objective_id: this.element.objective?.id || null,
        length: this.element.length || null,
        area: this.element.area || null,
        diameter_id: this.element.diameter?.id || null,
        cover: this.element.cover || null,
        number_in_row: this.element.number_in_row || null,
      };
      try {
        const response = await axios.post("/elementen/store", body);
        console.log(response);
      } catch (error) {
        console.error("Error saving changes:", error);
      }
    },
    hasPermission(perms) {
      const checkAuthorization = inject("checkAuthorization");
      return checkAuthorization?.(perms) || false;
    },
    search(event, fieldName) {
      const query = event.query.toLowerCase();
      const keysToCheck = [
        "owner",
        "location",
        "element_type_nl",
        "district",
        "shape_nl",
        "objective_nl",
        "diameter",
        "project",
      ];
      const filtered = Object.values(this.element_data[fieldName]).filter(
        (item) => {
          if (typeof item === "string") {
            return item.toLowerCase().includes(query);
          } else if (typeof item === "object" && item !== null) {
            return keysToCheck.some(
              (key) => key in item && item[key].toLowerCase().includes(query)
            );
          }
          return false;
        }
      );
      this.suggestions[fieldName] = filtered;
    },
    formatDate(date) {
      const options = { day: "2-digit", month: "short", year: "numeric" };
      return new Date(date)
        .toLocaleDateString("en-US", options)
        .replace(",", "");
    },
    elementHasOwner() {
      return this.element.owners.some(
        (owner) => owner.id == this.user.owner_id
      );
    },
    noteAdded(newNote) {
      // Called when the NotesContent component emits a note-added event.
      this.element.notes.unshift(newNote);
    },
  },
};
</script>

<style scoped>
/* Scoped styles specific to this component */
</style>
