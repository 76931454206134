<template>
  <div class="species-list">
    <div class="row">
      <div class="col-md-6">
        <h5>
          Flora
          <Button
            class="pull-right"
            v-if="hasPermission(['admin', 'user', 'edit_remove_flora_fauna'])"
            icon="pi pi-plus"
            variant="outlined"
            rounded
            aria-label=""
            @click="addNewFlora"
          />
        </h5>

        <br />
        <div
          v-for="(flora, index) in element.flora"
          :key="flora.id"
          class="mt-3"
        >
          <b>
            {{ flora.flora_nl }}
            <Button
              v-if="hasPermission(['admin', 'user', 'edit_remove_flora_fauna'])"
              icon="pi pi-times"
              variant="text"
              rounded
              aria-label="Remove Flora"
              class="pull-right"
              @click="removeFlora(flora, index)"
            />
          </b>
          <div v-if="flora.pivot.description">
            <p class="">{{ flora.pivot.description }}</p>
          </div>

          <div v-if="flora.pivot.recorded_at" class="">
            <small class="text-muted">{{
              formatDate(flora.pivot.recorded_at)
            }}</small>
          </div>
          <br />
        </div>
      </div>
      <div class="col-md-6">
        <h5>
          Fauna
          <Button
            class="pull-right"
            v-if="hasPermission(['admin', 'user', 'edit_remove_flora_fauna'])"
            icon="pi pi-plus"
            variant="outlined"
            rounded
            aria-label=""
            @click="addNewFauna"
          />
        </h5>
        <br />

        <div v-for="(fauna, index) in element.fauna" :key="fauna.id">
          <div class="" v-if="!fauna.pivot.approval_pending">
            <b>
              {{ fauna.fauna_nl }}
              <Button
                v-if="
                  hasPermission(['admin', 'user', 'edit_remove_flora_fauna'])
                "
                icon="pi pi-times"
                variant="text"
                rounded
                aria-label="Remove Fauna"
                class="pull-right"
                @click="removeFauna(fauna, index)"
              />
            </b>

            <div v-if="fauna.pivot.description">
              <p class="">{{ fauna.pivot.description }}</p>
            </div>

            <div v-if="fauna.pivot.recorded_at" class="">
              <small class="text-muted">{{
                formatDate(fauna.pivot.recorded_at)
              }}</small>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>

  <Dialog
    :draggable="false"
    v-model:visible="addFlora"
    modal
    header="Nieuwe soort"
    :style="{ width: '35vw', maxHeight: '80vh' }"
  >
    <div class="">
      <div class="col-12">
        <label for="flora">Selecteer een soort</label>
        <br />
        <AutoComplete
          id="flora"
          dropdown
          v-model="newFlora.flora"
          :suggestions="suggestions['flora']"
          optionLabel="flora_nl"
          @complete="search($event, 'flora')"
        />
      </div>

      <div class="col-12 md:col-6">
        <label for="date">Uitvoer datum</label>
        <br />
        <DatePicker
          id="date"
          v-model="newFlora.recorded_at"
          placeholder="Selecteer een datum"
          required
          class="w-full"
        />
      </div>

      <div class="col-12">
        <label for="description">Beschrijving</label>
        <br />
        <Textarea
          id="description"
          v-model="newFlora.description"
          rows="5"
          placeholder="Voer een beschrijving in"
          class="w-full"
        />
      </div>
    </div>

    <div class="dialog-footer mt-4">
      <Button
        label="Opslaan"
        severity="success"
        icon="pi pi-check"
        @click="saveFlora"
      />
    </div>
  </Dialog>

  <!-- Add Fauna Dialog -->
  <Dialog
    v-model:visible="addFauna"
    :draggable="false"
    modal
    header="Nieuwe soort"
    :style="{ width: '35vw', maxHeight: '80vh' }"
  >
    <div class="form-grid grid">
      <div class="col-12">
        <AutoComplete
          v-model="newFauna.fauna"
          dropdown
          :suggestions="suggestions['fauna']"
          optionLabel="fauna_nl"
          @complete="search($event, 'fauna')"
        />
      </div>

      <div class="col-12 md:col-6">
        <DatePicker
          v-model="newFauna.recorded_at"
          placeholder="Selecteer een datum"
          required
          class="w-full"
        />
      </div>

      <div class="col-12">
        <Textarea
          v-model="newFauna.description"
          rows="5"
          placeholder="Voer een beschrijving in"
          class="w-full"
        />
      </div>
    </div>

    <div class="dialog-footer mt-4">
      <Button
        label="Opslaan"
        severity="success"
        icon="pi pi-check"
        @click="saveFauna"
      />
    </div>
  </Dialog>

  <!-- PrimeVue ConfirmDialog Component -->
  <ConfirmDialog :draggable="false" />
</template>

<script>
import { ref, computed, inject } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import ConfirmDialog from "primevue/confirmdialog";
import axios from "axios";
import InputText from "primevue/inputtext";
import DatePicker from "primevue/datepicker";
import Select from "primevue/select";
import Textarea from "primevue/textarea";
import AutoComplete from "primevue/autocomplete";
import { useConfirm } from "primevue";

export default {
  name: "SpeciesForm",
  components: {
    Button,
    Select,
    DatePicker,
    Dialog,
    InputText,
    Textarea,
    AutoComplete,
    ConfirmDialog,
  },
  props: {
    element: Object,
    organisation: Object,
    user: Object,
    species: Object,
  },
  data() {
    return {
      editing: false,
      loading: true,
      addFlora: false,
      addFauna: false,
      newFlora: {
        flora: {},
        recorded_at: new Date(),
        description: "",
      },
      newFauna: {
        fauna: {},
        recorded_at: new Date(),
        description: "",
      },
      suggestions: {},
    };
  },
  setup() {
    const confirm = useConfirm();
    return { confirm };
  },
  methods: {
    addNewFlora() {
      this.addFlora = true;
    },
    async saveFlora() {
      this.newFlora.element_id = this.element.id;
      await axios
        .post("/elementen/store/species", this.newFlora)
        .then((response) => {
          if (response.data.success) {
            this.addFlora = false;
            this.element.flora.push({
              id: this.newFlora.flora.id,
              flora_nl: this.newFlora.flora.flora_nl,
              pivot: {
                description: this.newFlora.description,
                recorded_at: this.newFlora.recorded_at,
              },
            });
          }
        });
    },
    async saveFauna() {
      this.newFauna.element_id = this.element.id;
      await axios
        .post("/elementen/store/species", this.newFauna)
        .then((response) => {
          if (response.data.success) {
            this.addFauna = false;
            this.element.fauna.push({
              id: this.newFauna.fauna.id,
              fauna_nl: this.newFauna.fauna.fauna_nl,
              pivot: {
                description: this.newFauna.description,
                recorded_at: this.newFauna.recorded_at,
              },
            });
          }
        });
    },
    addNewFauna() {
      this.addFauna = true;
    },
    removeFlora(item, index) {
      this.confirm.require({
        message: "Weet u zeker dat u deze flora wilt verwijderen?",
        header: "Bevestig Verwijdering",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Verwijderen",
        rejectLabel: "Annuleren",
        accept: async () => {
          this.element.flora.splice(index, 1);
          this.$forceUpdate();

          const body = {
            element_id: this.element.id,
            flora: item,
          };

          await axios
            .post("/elementen/species/remove", body)
            .then((response) => {
              console.log(response);
            });
        },
      });
    },
    removeFauna(item, index) {
      this.confirm.require({
        message: "Weet u zeker dat u deze fauna wilt verwijderen?",
        header: "Bevestig Verwijdering",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Verwijderen",
        rejectLabel: "Annuleren",
        accept: async () => {
          this.element.fauna.splice(index, 1);
          this.$forceUpdate();

          const body = {
            element_id: this.element.id,
            fauna: item,
          };

          await axios
            .post("/elementen/species/remove", body)
            .then((response) => {
              console.log(response);
            });
        },
      });
    },
    hasPermission(perms) {
      const checkAuthorization = inject("checkAuthorization");
      return checkAuthorization?.(perms) || false;
    },
    formatDate(date) {
      if (!date) return "-";
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    elementHasOwner() {
      this.element.owners.forEach((owner) => {
        if (owner.id === this.user.owner_id) {
          return true;
        }
      });

      return false;
    },
    canEditElement() {
      if (
        this.user.roles.some((role) => role.slug === "owners") ||
        this.user.roles.some(
          (role) => role.slug === "nfw_deelnemers_project_centrale_as"
        )
      ) {
        if (
          this.element.owners.some((owner) => owner.id === this.user.owner_id)
        ) {
          return true;
        }
      }

      return false;
    },
    search(event, fieldName) {
      const query = event.query.toLowerCase();

      const filtered = Object.values(this.species[fieldName]).filter((item) => {
        if ("flora_nl" in item) {
          return item.flora_nl.toLowerCase().includes(query);
        } else if ("fauna_nl" in item) {
          return item.fauna_nl.toLowerCase().includes(query);
        }

        return false;
      });

      console.log(filtered);

      this.suggestions[fieldName] = filtered;
    },
  },
  mounted() {
    this.loading = false;
  },
};
</script>

<style scoped>
</style>
