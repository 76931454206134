<template>
  <div class="btn-group b_wrap_mass_editing">
    <div class="btn-group">
      <!-- Link to the map -->
      <Button 
        icon="pi pi-globe" 
        class="p-button-sm" 
        label="" 
        @click="navigateTo('/kaart/elementen')" 
        tooltip="Selectie overzetten naar de kaart" 
        tooltipOptions="{ position: 'top' }"
      />

      <!-- Excel export -->
      <Button 
        v-if="hasPermission(['admin', 'user', 'export'])" 
        icon="pi pi-file-excel" 
        class="p-button-sm" 
        label="" 
        @click="navigateTo('/elementen/excel-export')" 
        tooltip="Exporteren" 
        tooltipOptions="{ position: 'top' }"
      />

      <!-- Shape export -->
      <Button 
        v-if="hasPermission(['admin', 'user', 'export'])" 
        icon="pi pi-download" 
        class="p-button-sm" 
        label="" 
        @click="navigateTo('/elementen/shape-export')" 
        tooltip="Exporteren" 
        tooltipOptions="{ position: 'top' }"
      />

      <!-- Print -->
      <Button 
        icon="pi pi-print" 
        class="p-button-sm" 
        label="" 
        @click="navigateTo('/elementen/print')" 
        tooltip="Print maken" 
        tooltipOptions="{ position: 'top' }"
      />
    </div>
  </div>
</template>
<script>
import { inject } from "vue";
import ToggleSwitch from "primevue/toggleswitch";
import axios from "axios";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import { ref } from "vue";
import Select from "primevue/select";

export default {
  components: {
    ToggleSwitch,
    Dialog,
    Button,
    Select,
  },
  emits: ["update-table", "update:modelValue"],
  props: {
    organisation: {
      type: Object,
      required: true,
    },
  },
  setup(_, { emit }) {
    const checkAuthorization = inject("checkAuthorization");

    const hasPermission = (perms) => {
      return checkAuthorization?.(perms);
    };

    const navigateTo = (path) => {
      window.open(path, '_blank');
    }


    return {
      hasPermission,
      navigateTo
    };
  },
};
</script>
  
<style scoped>
</style>
  