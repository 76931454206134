/* Admin page js */
$(function() {

	if($('.image-popup').length) {
		$('.image-popup').magnificPopup({
			type: 'image',
			closeOnContentClick: true,
			mainClass: 'mfp-img-mobile',
			image: {
				verticalFit: true
			},
			zoom: {
				enabled: true
			}
		});
	}

		$(".chosen-select.m_single > select:visible").each(function() {
			var deselectable = $(this).closest(".chosen-select").hasClass("m_deselectable");
			$(this).chosen({
				max_selected_options: 1,
				allow_single_deselect: deselectable,
				placeholder_text_single: 'Kies een optie',
				placeholder_text_multiple: 'Kies opties',
				width: '100%'
			});
		});

        $(".chosen-select.m_multy > select:visible").each(function() {
            var deselectable = $(this).closest(".chosen-select").hasClass("m_deselectable");
            $(this).chosen({
                width: '100%',
                allow_single_deselect: deselectable,
                placeholder_text_multiple: ""
            });
        });

	$(".getMapEmptyPDF").on('click', function() {
		var self = $(this);
		var print_area_id = $("select[name=print_area_id]").val();
		if(!print_area_id) return false;
		window.open(self.data("url") + "/" + print_area_id + "/pdf",'_blank');
		return false;
	});

	$(".getMapFillForm").on('click', function() {
		var self = $(this);
		var print_area_id = $("select[name=print_area_id]").val();
		if(!print_area_id) return false;
		document.location = self.data("url") + "/" + print_area_id;
		return false;
	});

	if ($("#maintenance_form").length) {
		var form = $("#maintenance_form form");

		form.find(".section.todo").on("change", ".chosen-select.m_single > select", function(e) {
			$(this).closest(".row").find(".form-group > input[type='text']")
				.attr("name", "note[" + $(this).val() + "]");
			if ($(this).val() != '') {
				$(this).closest(".row").find(".form-group > input[type='text']")
					.removeAttr("disabled")
					.focus();
			} else {
				$(this).closest(".row").find(".form-group > input[type='text']")
					.prop("disabled", "disabled");				
			}
		})

		form.on("click", ".section.todo .e_add", function(e) {
			e.preventDefault();

			$(this).closest(".row").before(tmpl('template-maint-suggestion')());

			form.find(".chosen-select.m_single > select:visible").each(function() {
				var deselectable = $(this).closest(".chosen-select").hasClass("m_deselectable");
				$(this).chosen({
					max_selected_options: 1,
					allow_single_deselect: deselectable,
					placeholder_text_single: 'Kies een optie',
					placeholder_text_multiple: 'Kies opties',
					width: '100%'
				});
			});
		})

		form.on("click", ".section.todo .e_remove", function(e) {
			e.preventDefault();

			$(this).closest(".row").remove();
		});

		form.on("submit", function(e) {
            if(form.hasClass('direct_submit')) {
                return true;
            }

            e.preventDefault();

			if (!form.hasClass('is_valid')) {
				form
					.addClass("submit_after")
					.find("[data-validate]").blur();
					return;
			}
			if (page_var.maintenanceFormAddSent == true) return;
			page_var.maintenanceFormAddSent = true;

			var data = form.serializeObject();
			form.addClass("m_loading");
			$.post(form.attr("action"), data, function(response, textStatus, jqXHR) {
				page_var.maintenanceFormAddSent = false;
				form.removeClass("m_loading");

				if (response.success == true) {
					$.notify("Success, reloading page");
					if(response.url) window.location = response.url;
					else location.reload();
				} else {
					$.notify(response.content, "warning");
				}
			}, "json")

		});

	}

    $("body").on("click", ".element_files .remove_file, .map_files .remove_file", function(e) {
        e.preventDefault();
        if(!confirm("Weet je het zeker?")) return false;
        var button = $(this);
        $.post(button.attr("href"), function(response) {
            if(response.success) {
                button.parents(".element_file").remove();
            }
        }, "json");
    });
});