<template>
  <div v-if="loaded">
    <div class="detail" v-for="feature in features" :key="feature.lsn">
      <!-- Edit page for BOOM NFW Centrale As -->
      <div class="edit" v-if="feature.values_.edit">
        <div class="detailform-content">
          <h4>
            Bewerk "{{ feature.get("lsn") }}"
            <a
              class="pt-3 pl-3"
              v-on:click="duplicate(feature)"
              v-if="$store.getters.canCreate"
              ><span
                class="glyphicon glyphicon-duplicate"
                aria-hidden="true"
              ></span
            ></a>
            <button
              type="button"
              class="btn btn-default"
              v-on:click="enableEdit(feature)"
            >
              Bewerken
            </button>
          </h4>

          <p>
            Meer informatie:
            <a v-bind:href="'/elementen/' + feature.get('id')" target="_blank"
              >Detailpagina</a
            >
          </p>

          <form
            name="opslaan"
            class="iup-form"
            @submit.prevent="processRequest(feature)"
          >
            <fieldset>
              <!-- <div class="row">
                            <div class="col-sm-12">
                                <form>
                                    <input placeholder="LSN" v-model="feature.lsn" name="lsn" required>
                                </form>
                            </div>
                        </div> -->
              <div class="row">
                <div class="col-sm-12">
                  <form>
                    <label for="location_id">Locatie</label>
                    <select
                      placeholder="Locatie"
                      v-model="feature.values_.location_id"
                      name="location_id"
                      required
                      class="form-control"
                    >
                      <option
                        v-for="(e, id) in elements_data.locationsArr"
                        :key="id"
                        v-bind:value="id"
                      >
                        {{ e }}
                      </option>
                    </select>
                  </form>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <form>
                    <label for="element_type">Elementtype</label>
                    <select
                      placeholder="Elementtype"
                      v-model="feature.values_.element_type_id"
                      name="element_type"
                      required
                      class="form-control"
                    >
                      <option
                        v-for="(e, id) in elements_data.element_typesArr"
                        :key="id"
                        v-bind:value="id"
                      >
                        {{ e }}
                      </option>
                    </select>
                  </form>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <form>
                    <label for="shape_id">Vorm</label>
                    <select
                      placeholder="Vorm"
                      v-model="feature.values_.shape_id"
                      name="shape_id"
                      class="form-control"
                    >
                      <option
                        v-for="(e, id) in elements_data.shapesArr"
                        :key="id"
                        v-bind:value="id"
                      >
                        {{ e }}
                      </option>
                    </select>
                  </form>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <form>
                    <label for="objective_id">Streefbeeld</label>
                    <select
                      placeholder="Streefbeeld"
                      v-model="feature.values_.objective_id"
                      name="objective_id"
                      required
                      class="form-control"
                    >
                      <option
                        v-for="(e, id) in elements_data.objectivesArr"
                        :key="id"
                        v-bind:value="id"
                      >
                        {{ e }}
                      </option>
                    </select>
                  </form>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <form>
                    <label for="diameter_id">Diameter</label>
                    <select
                      placeholder="Diameter"
                      v-model="feature.values_.diameter_id"
                      name="diameter_id"
                      required
                      class="form-control"
                    >
                      <option
                        v-for="(e, id) in elements_data.diametersArr"
                        :key="id"
                        v-bind:value="id"
                      >
                        {{ e }}
                      </option>
                    </select>
                  </form>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <form>
                    <label for="cover">Bedekkingsgraad</label>
                    <select
                      placeholder="Bedekkingsgraad"
                      v-model="feature.values_.cover"
                      name="cover"
                      class="form-control"
                    >
                      <option value="0">N.v.t.</option>
                      <option value="10">0 - 10%</option>
                      <option value="20">10 - 20%</option>
                      <option value="30">20 - 30%</option>
                      <option value="40">30 - 40%</option>
                      <option value="50">40 - 50%</option>
                      <option value="60">50 - 60%</option>
                      <option value="70">60 - 70%</option>
                      <option value="80">70 - 80%</option>
                      <option value="90">80 - 90%</option>
                      <option value="99">90 - 100%</option>
                      <option value="100">100%</option>
                    </select>
                  </form>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <form>
                    <input
                      type="number"
                      placeholder="Aantal"
                      v-model="feature.values_.number_in_row"
                      name="number_in_row"
                    />
                  </form>
                </div>
              </div>

              <div class="button-row">
                <button
                  type="button"
                  class="btn btn-default"
                  v-on:click="cancel()"
                >
                  Annuleren
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  v-on:click="remove(feature)"
                >
                  Verwijderen
                </button>
                <button type="submit" class="btn btn-success pull-right">
                  Opslaan
                </button>
              </div>
            </fieldset>
          </form>
          <hr />
        </div>
      </div>

      <!-- Detail page -->
      <div class="detail" v-else>
        <h4>
          {{ feature.get("lsn") }} -
          {{
            feature.get("elementtype")
              ? feature.get("elementtype").element_type_nl
              : ""
          }}
          <a
            class="pt-3 pl-3"
            v-on:click="duplicate(feature)"
            v-if="$store.getters.canCreate"
            ><span
              class="glyphicon glyphicon-duplicate"
              aria-hidden="true"
            ></span
          ></a>
          <button
            type="button"
            class="btn btn-default"
            v-if="feature.values_.canEdit"
            v-on:click="enableEdit(feature)"
          >
            Bewerken
          </button>
        </h4>

        <p>
          Meer informatie:
          <a v-bind:href="'/elementen/' + feature.get('id')" target="_blank"
            >Detailpagina</a
          >
        </p>

        <tabs :options="{ useUrlFragment: false }">
          <tab name="Algemeen">
            <table class="table">
              <tbody>
                <tr>
                  <th>Eigenaar</th>
                  <td>
                    {{
                      feature.get("owners").length
                        ? feature
                            .get("owners")
                            .map((owner) => {
                              return owner.owner;
                            })
                            .toString()
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Locatie</th>
                  <td>
                    {{
                      feature.get("location")
                        ? feature.get("location").location
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Lengte</th>
                  <td>{{ feature.get("length") }}</td>
                </tr>
                <tr>
                  <th>Vorm</th>
                  <td>
                    {{
                      feature.get("shape") ? feature.get("shape").shape_nl : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Opp (m2)</th>
                  <td>{{ feature.get("area") }}</td>
                </tr>
                <tr>
                  <th>Streefbeeld</th>
                  <td>
                    {{
                      feature.get("objective")
                        ? feature.get("objective").objective_nl
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Diameter</th>
                  <td>
                    {{
                      feature.get("diameter")
                        ? feature.get("diameter").diameter
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Aantal</th>
                  <td>{{ feature.get("number_in_row") }}</td>
                </tr>
              </tbody>
            </table>
          </tab>
          <tab name="Onderhoud" v-if="feature.get('maintenance').length">
            <template
              v-for="maintenance in feature.get('maintenance')"
              :key="maintenance.id"
            >
              <table class="table">
                <thead></thead>
                <tbody>
                  <tr>
                    <th>Maatregel</th>
                    <td>
                      {{
                        maintenance.maintenancetype
                          ? maintenance.maintenancetype.maintenance_type_nl
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>Periode</th>
                    <td>
                      {{
                        new Date(maintenance.period_from).toDateString() +
                        " / " +
                        new Date(maintenance.period_to).toDateString()
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>Cyclus</th>
                    <td>
                      {{ maintenance.cycle ? maintenance.cycle.cycle_nl : "" }}
                    </td>
                  </tr>
                  <tr>
                    <th>Fasering</th>
                    <td>
                      {{
                        maintenance.phasing ? maintenance.phasing.phasing : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>Uitvoerder</th>
                    <td>{{ maintenance.executed_by_id }}</td>
                  </tr>
                  <tr>
                    <th>Rekenwaarde</th>
                    <td>{{ maintenance.calculation_value }} stuks</td>
                  </tr>
                  <tr>
                    <th>Gereedschap</th>
                    <td>
                      {{
                        maintenance.tool && maintenance.tool.length
                          ? maintenance.tool[0].tool_nl
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>Vrijgekomen materiaal</th>
                    <td>
                      {{
                        maintenance.waste && maintenance.waste.length
                          ? maintenance.waste[0].waste_nl
                          : ""
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </tab>
        </tabs>
        <hr />
      </div>
    </div>
  </div>
</template>

<style>
div.detail {
  max-width: 100%;
  max-height: 100%;
  margin-top: 10%;
  font-size: 11px;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 700px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

img.client-image {
  max-width: 200px;
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:not(:last-child) {
  border-bottom: dotted 1px #ddd;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 700px) {
  .tabs-component-tab {
    margin-right: 0.5em;
    transform: translateY(2px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 700px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 4em 2em;
  }
}
</style>

<script>
import * as moment from "moment";
import axios from "axios";
import GeoJSON from "ol/format/GeoJSON";
import Collection from "ol/Collection";
import Modify from "ol/interaction/Modify";
import { Style, Fill, Stroke, Circle, Icon, Text } from "ol/style.js";
import Vector from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

export default {
  name: "DetailComponent",
  components: {},
  props: {
    user: Object,
    organisation: Object,
    features: Array,
    map: Object,
    infoLayer: Object,
    elements_data: Object,
  },
  watch: {},
  data() {
    return {
      onderhoud: [
        "Maatregel",
        "Periode",
        "Cyclus",
        "Fasering",
        "Uitvoerder",
        "Rekenwaarde",
        "Gereedschap",
        "Vrijgekomen materiaal",
        "Verwerking",
      ],
      modify: undefined,
      modifyLayer: new Vector({ source: new VectorSource() }),
      elements: [],
      loaded: false,
      editFeatures: new Collection(),
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "#ffcc33",
          width: 2,
        }),
        image: new Circle({
          radius: 7,
          fill: new Fill({
            color: "#ffcc33",
          }),
        }),
      }),
    };
  },
  mounted() {
    this.processData();
  },
  beforeDestroy() {
    this.$store.getters.getMap.removeInteraction(this.modify);
    this.$store.getters.getMap.removeLayer(this.modifyLayer);
  },
  methods: {
    processData() {
      let config = this.organisation.map_config;
      this.editFeatures.clear();

      // const editFeatures
      this.features.forEach((f) => {
        f.values_.canEdit = false;

        if (this.organisation.id === 4) {
          for (let owner of f.values_.owners) {
            if (owner.id === this.user.owner_id) {
              f.values_.canEdit = true;
            }
          }
        }

        console.log(f.values_.canEdit);

        // Check if the user is allowed to edit
        this.user.roles.forEach((role) => {
          const r = JSON.parse(role.permissions);
          if (
            (r.edit_element_general && this.organisation.id !== 4) ||
            r.admin
          ) {
            f.values_.canEdit = true;
          }
        });

        console.log(f.values_.canEdit);
        if (f.values_.canEdit) {
          // Fix date formats
          if (f.values_.begindatum) {
            f.values_.begindatum = moment(
              f.values_.begindatum,
              "YYYY-MM-DD"
            ).toISOString();
          }

          if (f.values_.einddatum) {
            f.values_.einddatum = moment(
              f.values_.einddatum,
              "YYYY-MM-DD"
            ).toISOString();
          }

          // this.editFeatures.push(f.clone());
        }
      });

      // Make sure we can edit it
      this.editFeatureGeometry();
      this.loaded = true;
    },

    remove(feature) {
      if (
        confirm(
          "Weet je zeker dat je het geselecteerde object wilt verwijderen? " +
            name
        )
      ) {
        axios({
          method: "delete",
          url: elementDeleteUrl + "/" + feature.get("id"),
        }).then((res) => {
          for (let layer of this.$store.getters.getMap.getLayers().array_) {
            if (typeof layer.getSource().clear === "function") {
              layer.getSource().clear();
            } else {
              layer.getSource().refresh();
            }
          }

          this.cancel();
        });
      }
    },

    cancel() {
      this.$store.getters.getMap.removeInteraction(this.modify);
      // this.infoLayer.getSource().clear();
      // this.features = undefined;
      this.$store.commit("setActiveTab", "Legenda");
    },

    enableEdit(feature) {
      this.modifyLayer.getSource().clear();
      feature.values_.edit = !feature.get("edit");
      this.modifyLayer.getSource().addFeature(feature);
    },

    editFeatureGeometry() {
      this.$store.getters.getMap.addLayer(this.modifyLayer);
      this.modifyLayer.setStyle(this.style);
      if (this.modify) {
        this.$store.getters.getMap.removeInteraction(this.modify);
      }

      // Now allow the user to change the selected vector and activate the interaction tool
      this.modify = new Modify({
        source: this.modifyLayer.getSource(),
        // features: this.editFeatures,
        style: this.style,
        // delete vertices by pressing the SHIFT key
        // deleteCondition: function(event) {
        //     return ol.events.condition.shiftKeyOnly(event) &&
        //     ol.events.condition.singleClick(event);
        // }
      });

      // add it to the map
      this.$store.getters.getMap.addInteraction(this.modify);
    },

    /**
     * Create a insert, update or delete request to the server and send it to the server
     */
    processRequest(feature) {
      const element = feature.getProperties();

      if (this.featureType === "elements_nfw_centrale_as") {
        element.organisation_id = 14;
        element.district_id = 25;
        element.created_by = 1;
        // element.owner_id = 4171;
        delete element.type;
        this.feature.unset("type");
      }

      let editedFeature;
      // Find updated geometry
      this.modifyLayer
        .getSource()
        .getFeatures()
        .forEach((f) => {
          if (f.getId() === feature.getId()) {
            editedFeature = f;
          }
        });

      let geom = editedFeature.getGeometry();

      if (geom.getType() === "LineString") {
        let length = Math.round(geom.getLength());
        if (element.length) {
          element.length = length;
        }
      } else if (geom.getType() === "Polygon") {
        let area = Math.round(geom.getArea());
        if (element.area) {
          element.area = area;
        }
      }

      element.geom = new GeoJSON().writeFeatureObject(editedFeature).geometry;

      this.sendRequest(element).then((response) => {
        // Refresh all vector layers
        for (let layer of this.$store.getters.getMap.getLayers().array_) {
          if (typeof layer.getSource().clear === "function") {
            layer.getSource().clear();
          } else {
            layer.getSource().refresh();
          }
        }

        this.$store.commit("setActiveTab", "Legenda");
      });
    },

    sendRequest(data) {
      let headers = {};
      return axios({
        method: "post",
        url: elementsUpdateUrl,
        data,
      });
    },

    duplicate(feature) {
      let newFeature = feature.clone();
      newFeature.getGeometry().translate(1, 0);
      this.$store.commit("setNewFeature", newFeature);
      console.log(feature);

      this.cancel();
      this.$store.commit("setActiveTab", "Create");
    },
  },
};
</script>
