<template>
  <div class="p-4">
    <h4 class="p-mb-4">Nieuwe eigenaar</h4>

    <form @submit.prevent="submitForm" class="p-fluid">
      <Message v-if="success" severity="success" :closable="false" class="p-mb-4">
        {{ success }}
      </Message>

      <div class="p-field">
        <label for="owner">Eigenaar</label>
        <InputText v-model="form.owner" id="owner" placeholder="Eigenaar" />
        <small v-if="errors.owner" class="p-error">{{ errors.owner }}</small>
      </div>

      <div class="p-field">
        <label for="street">Straat</label>
        <InputText v-model="form.street" id="street" placeholder="Straat" />
        <small v-if="errors.street" class="p-error">{{ errors.street }}</small>
      </div>

      <div class="p-field">
        <label for="postal">Postcode</label>
        <InputText v-model="form.postal" id="postal" placeholder="Postcode" />
        <small v-if="errors.postal" class="p-error">{{ errors.postal }}</small>
      </div>

      <div class="p-field">
        <label for="city">Woonplaats</label>
        <InputText v-model="form.city" id="city" placeholder="Woonplaats" />
        <small v-if="errors.city" class="p-error">{{ errors.city }}</small>
      </div>

      <div class="p-field">
        <label for="phonenumber">Telefoonnummer</label>
        <InputText v-model="form.phonenumber" id="phonenumber" placeholder="Telefoonnummer" />
        <small v-if="errors.phonenumber" class="p-error">{{ errors.phonenumber }}</small>
      </div>

      <!-- Submit Button -->
      <Button type="submit" label="Opslaan" icon="pi pi-check" class="p-mt-3" />
    </form>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Message from 'primevue/message';
import axios from 'axios';

export default {
  components: {
    InputText,
    Button,
    Message,
  },
  data() {
    return {
      form: {
        owner: '',
        street: '',
        postal: '',
        city: '',
        phonenumber: '',
      },
      success: null,
      errors: {},
    };
  },
  methods: {
    async submitForm() {
      try {
        const response = await axios.post('/owners/new_owner', this.form);
        this.success = response.data.message || 'Owner created successfully!';
        this.errors = {};
        this.resetForm();
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.errors = error.response.data.errors;
        } else {
          console.error(error);
        }
      }
    },
    resetForm() {
      this.form = {
        owner: '',
        street: '',
        postal: '',
        city: '',
        phonenumber: '',
      };
    },
  },
};
</script>

<style scoped>
.p-field {
  margin-bottom: 1rem;
}

.p-error {
  font-size: 0.875rem;
}
</style>
