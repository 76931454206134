<template>
  <div class="">
    <!-- <h4 class="margin-top-0">
            Soorten
            <div v-if="hasPermission(['admin', 'user', 'edit_remove_flora_fauna'])" class="element__edit click-edit pull-right">
                <button class="btn btn-default btn-xs" @click="toggleEditing">
                    Bewerken
                </button>
            </div>
            <div v-if="editing" class=" pull-right">
                <button class="btn btn-success btn-xs" @click="saveChanges">Opslaan</button>
            </div>
        </h4> -->
    <div class="row">
      <div class="col-md-6">
        <h5>
          Ziekten
          <Button
            class="pull-right"
            v-if="hasPermission(['admin', 'user', 'add_diseases_plagues'])"
            icon="pi pi-plus"
            variant="outlined"
            rounded
            aria-label=""
            @click="addDisease = true"
          />
        </h5>
        <br />

        <div
          v-for="(disease, index) in element.diseases"
          :key="disease.id"
          class=""
        >
          <b>
            {{ disease.diseases_nl }}
            <Button
              v-if="
                hasPermission(['admin', 'user', 'edit_remove_diseases_plagues'])
              "
              icon="pi pi-times"
              variant="text"
              rounded
              aria-label="Remove disease"
              class="pull-right"
              @click="removeDisease(disease, index)"
            />
          </b>
          <div v-if="disease.pivot.description">
            <p class="">{{ disease.pivot.description }}</p>
          </div>

          <div v-if="disease.pivot.recorded_at" class="">
            <small class="text-muted">{{
              formatDate(disease.pivot.recorded_at)
            }}</small>
          </div>
        </div>
        <br />
      </div>
      <div class="col-md-6">
        <h5>
          Plagen
          <Button
            class="pull-right"
            v-if="hasPermission(['admin', 'user', 'add_diseases_plagues'])"
            icon="pi pi-plus"
            variant="outlined"
            rounded
            aria-label=""
            @click="addPlague = true"
          />
        </h5>
        <br />

        <div v-for="(plague, index) in element.plagues" :key="plague.id">
          <div class="" v-if="!plague.pivot.approval_pending">
            <b>
              {{ plague.plague_nl }}
              <Button
                v-if="
                  hasPermission([
                    'admin',
                    'user',
                    'edit_remove_diseases_plagues',
                  ])
                "
                icon="pi pi-times"
                variant="text"
                rounded
                aria-label="Remove plague"
                class="pull-right"
                @click="removePlague(plague, index)"
              />
            </b>

            <div v-if="plague.pivot.description">
              <p class="">{{ plague.pivot.description }}</p>
            </div>

            <div v-if="plague.pivot.recorded_at" class="">
              <small class="text-muted">{{
                formatDate(plague.pivot.recorded_at)
              }}</small>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>

  <Dialog
    v-model:visible="addPlague"
    :draggable="false"
    modal
    header="Plaag toevoegen"
    :style="{ width: '35vw', maxHeight: '80vh' }"
  >
    <div class="col-12">
      <label for="plague">Selecteer een soort</label>
      <br />
      <AutoComplete
        id="plague"
        v-model="newPlague.plague"
        :suggestions="suggestions['plague']"
        optionLabel="plagues_nl"
        dropdown
        @complete="search($event, 'plague')"
      />
    </div>

    <div class="col-12 md:col-6">
      <label for="date">Uitvoer datum</label>
      <br />
      <DatePicker
        id="date"
        v-model="newPlague.recorded_at"
        placeholder="Selecteer een datum"
        required
        class="w-full"
      />
    </div>

    <div class="col-12">
      <label for="description">Beschrijving</label>
      <br />
      <Textarea
        id="description"
        v-model="newPlague.description"
        rows="5"
        placeholder="Voer een beschrijving in"
        class="w-full"
      />
    </div>

    <div class="dialog-footer mt-4">
      <Button
        label="Opslaan"
        severity="success"
        icon="pi pi-check"
        @click="savePlague"
      />
    </div>
  </Dialog>

  <Dialog
    v-model:visible="addDisease"
    :draggable="false"
    modal
    header="Ziekte toevoegen"
    :style="{ width: '35vw', maxHeight: '80vh' }"
  >
    <div class="form-grid grid">
      <div class="col-12">
        <AutoComplete
          v-model="newDisease.disease"
          :suggestions="suggestions['disease']"
          optionLabel="diseases_nl"
          dropdown
          @complete="search($event, 'disease')"
        />
      </div>

      <div class="col-12 md:col-6">
        <DatePicker
          v-model="newDisease.recorded_at"
          placeholder="Selecteer een datum"
          required
          class="w-full"
        />
      </div>

      <div class="col-12">
        <Textarea
          v-model="newDisease.description"
          rows="5"
          placeholder="Voer een beschrijving in"
          class="w-full"
        />
      </div>
    </div>

    <div class="dialog-footer mt-4">
      <Button
        label="Opslaan"
        severity="success"
        icon="pi pi-check"
        @click="saveDisease"
      />
    </div>
  </Dialog>
</template>

<script>
import { ref, computed, inject } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import axios from "axios";
import InputText from "primevue/inputtext";
import DatePicker from "primevue/datepicker";
import Select from "primevue/select";
import Textarea from "primevue/textarea";
import AutoComplete from "primevue/autocomplete";

export default {
  name: "Threats",
  components: {
    Button,
    Select,
    DatePicker,
    Dialog,
    InputText,
    Textarea,
    AutoComplete,
  },
  props: {
    element: Object,
    organisation: Object,
    user: Object,
    threats: Object,
  },
  data() {
    return {
      editing: false,
      loading: true,
      addPlague: false,
      addDisease: false,
      newPlague: {
        plague: {},
        recorded_at: new Date(),
        description: "",
      },
      newDisease: {
        disease: {},
        recorded_at: new Date(),
        description: "",
      },
      suggestions: {},
    };
  },
  methods: {
    async savePlague() {
      this.newPlague.element_id = this.element.id;
      await axios
        .post("/elementen/store/threats", this.newPlague)
        .then((response) => {
          if (response.data.success) {
            this.addPlague = false;

            this.element.plagues.push({
              id: this.newPlague.plague.id,
              plague_nl: this.newPlague.plague.plague_nl,
              pivot: {
                description: this.newPlague.description,
                recorded_at: this.newPlague.recorded_at,
              },
            });
          }
        });
    },
    async saveDisease() {
      this.newDisease.element_id = this.element.id;
      await axios
        .post("/elementen/store/threats", this.newDisease)
        .then((response) => {
          if (response.data.success) {
            this.addDisease = false;

            this.element.diseases.push({
              id: this.newDisease.disease.id,
              disease_nl: this.newDisease.disease.disease_nl,
              pivot: {
                description: this.newDisease.description,
                recorded_at: this.newDisease.recorded_at,
              },
            });
          }
        });
    },
    async removePlague(item, index) {
      this.element.plagues.splice(index, 1);
      this.$forceUpdate();

      const body = {
        element_id: this.element.id,
        plague: item,
      };

      await axios.post("/elementen/threats/remove", body).then((response) => {
        console.log(response);
      });
    },
    async removeDisease(item, index) {
      this.element.diseases.splice(index, 1);
      this.$forceUpdate();

      const body = {
        element_id: this.element.id,
        disease: item,
      };

      await axios.post("/elementen/threats/remove", body).then((response) => {
        console.log(response);
      });
    },
    hasPermission(perms) {
      const checkAuthorization = inject("checkAuthorization");
      return checkAuthorization?.(perms) || false;
    },
    formatDate(date) {
      if (!date) return "-";
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    elementHasOwner() {
      this.element.owners.forEach((owner) => {
        if (owner.id === this.user.owner_id) {
          return true;
        }
      });

      return false;
    },
    canEditElement() {
      if (
        this.user.roles.some((role) => role.slug === "owners") ||
        this.user.roles.some(
          (role) => role.slug === "nfw_deelnemers_project_centrale_as"
        )
      ) {
        if (
          this.element.owners.some((owner) => owner.id === this.user.owner_id)
        ) {
          return true;
        }
      }

      return false;
    },
    search(event, fieldName) {
      const query = event.query.toLowerCase();

      // Filter de array
      const filtered = Object.values(this.threats[fieldName]).filter((item) => {
        if ("plagues_nl" in item) {
          return item.plagues_nl.toLowerCase().includes(query.toLowerCase());
        } else if ("diseases_nl" in item) {
          return item.diseases_nl.toLowerCase().includes(query.toLowerCase());
        }

        return false;
      });

      console.log(filtered);

      // Zet de gefilterde resultaten in de juiste veld-sleutel binnen `suggestions`
      this.suggestions[fieldName] = filtered;
    },
  },
  mounted() {
    this.loading = false;
  },
};
</script>

<style scoped>
</style>
