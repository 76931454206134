<template>
  <div class="p-4">
    <Card>
      <template #title>NAW Contactpersoon</template>

      <template #content>

        <form @submit.prevent="submitForm" class="p-fluid">
          <div class="p-field">
            <label for="first_name">Voornaam</label>
            <InputText
              id="first_name"
              v-model="form.first_name"
              placeholder="Voornaam"
              required
            />
          </div>

          <div class="p-field">
            <label for="last_name">Achternaam</label>
            <InputText
              id="last_name"
              v-model="form.last_name"
              placeholder="Achternaam"
              required
            />
          </div>

          <div class="p-field">
            <label for="street">Adres</label>
            <InputText
              id="street"
              v-model="form.street"
              placeholder="Adres"
            />
          </div>


          <div class="p-field">
            <label for="postal">Postcode</label>
            <InputText
              id="postal"
              v-model="form.postal"
              placeholder="Postcode"
            />
          </div>

          <div class="p-field">
            <label for="residence">Woonplaats</label>
            <InputText
              id="residence"
              v-model="form.residence"
              placeholder="Woonplaats"
            />
          </div>

          <div class="p-field">
            <label for="phone">Telefoonnr.</label>
            <InputText
              id="phone"
              v-model="form.phone"
              placeholder="Telefoonnr."
            />
          </div>

          <div class="p-field">
            <label for="email">Email</label>
            <InputText
              id="email"
              v-model="form.email"
              placeholder="Email"
            />
          </div>

          <!-- Submit Button -->
          <Button type="submit" label="Opslaan" icon="pi pi-save" class="p-mt-3" />
        </form>

        <Message v-if="success" severity="success" :closable="false" class="p-mt-3">
          {{ success }}
        </Message>

      </template>
    </Card>
  </div>
</template>

<script>
import axios from 'axios';
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Message from 'primevue/message';

export default {
  components: {
    Card,
    InputText,
    Button,
    Message,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        contact_id: this.element.contact?.id || null,
        element_id: this.element.id,
        first_name: this.element.contact?.first_name || '',
        last_name: this.element.contact?.last_name || '',
        street: this.element.contact?.street || '',
        postal: this.element.contact?.postal || '',
        residence: this.element.contact?.residence || '',
        phone: this.element.contact?.phone || '',
        email: this.element.contact?.email || '',
      },
      success: null,
    };
  },
  methods: {
    async submitForm() {
      try {
        const url = this.element.contact ? '/contact/update' : '/contact/create';
        // const method = this.element.contact ? 'put' : 'post';

        await axios({
          method: 'post',
          url,
          data: this.form,
        });

        this.success = 'Contact successfully saved!';
      } catch (error) {
        console.error('Error saving contact:', error);
      }
    },
  },
};
</script>

<style scoped>
.p-field {
  margin-bottom: 1rem;
}
</style>
